import React from 'react'
import FintupComponent from '../FintupComponent'
import { doLoginSocial } from '../../utils/fintup-api'
import './SocialLogin.css'
import { GoogleAPI, GoogleLogin } from 'react-google-oauth'
import config from '../../config/config'

class SocialLogin extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      renderGoogleButton: true
    }
  }

  googleLoginSuccess (data) {
    //Dynamic property, protect from changes of Google responses
    let dataObject
    for(var property in data){
      if(data[property].hasOwnProperty('access_token')){
        dataObject = data[property]
        break;
      }
    }
    let loginSocialData = {
      accessToken: dataObject.access_token,
      idToken: dataObject.id_token,
      loginHint: dataObject.login_hint,
      loginType: 'google'
    }
    doLoginSocial(loginSocialData)
      .then(this.props.successLogin)
      .catch(this.props.errorLogin)
  }

  // /////////////////////

  onInitFailure (data) {
    console.log('ko', data, 'No se ha podido cargar el login de google')
    this.setStatePromise({ renderGoogleButton: false })
  }

  onUpdateSigninStatus (data) {
    console.log('cambio de estado en el login ok', data)
  }

  responseFailureGoogle (data) {
    console.log('KO -> No se ha podido realizar el login con google:', data)
    this.props.showAlert({ textKey: 'Login.google.ko' })
  }

  get googleButton () {
    return config.socialLogin && this.state.renderGoogleButton
      ? (
        <GoogleAPI clientId={config.googleLoginApiKey}
          onUpdateSigninStatus={this.onUpdateSigninStatus.bind(this)}
          onInitFailure={this.onInitFailure.bind(this)} >
          <GoogleLogin text="Login" width="125px"
            onLoginSuccess={this.googleLoginSuccess.bind(this)}
            onLoginFailure={this.responseFailureGoogle.bind(this)} />
        </GoogleAPI>
      )
      : ''
  }

  render () {
    return (
      <div className="socialLogin">
        {this.googleButton}
      </div>
    )
  }
}

export default SocialLogin
