import React from 'react'
import FintupComponent from '../FintupComponent'
import UserController from '../../core/UserMobx'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import './Header.css'
import navUtils from '../../core/navUtils'

class Header extends FintupComponent {
  /**
   *
   */
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  get renderUserData() {
    return UserController.user.name
      ? <div className="userData">
        <p><FormattedMessage id="header.user.user" values={{ value: UserController.user.name }} /></p>
        <p><FormattedMessage id="header.user.dni" values={{ value: UserController.user.dni, userId: UserController.user.id }} /></p>
        <p><FormattedMessage id="header.user.email" values={{ value: UserController.user.email }} /></p>
      </div>
      : ''
  }

  render() {
    return (
      <div className="fintupHeader">
        <a href="#" onClick={() => navUtils.goToInitUrl()}><img src="/img/logo-fintup-horizontal.svg" alt="logo" /></a>
        {this.renderUserData}
      </div>
    )
  }
}

export default observer(Header)
