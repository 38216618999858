import FintupComponent from './FintupComponent'
import SessionController from '../core/SessionMobx'
import { tGet } from '../utils/transverser'
import UserController from '../core/UserMobx'
import RSVP from 'rsvp'
import { adminUserDetails, adminUserDetailsByEmail } from '../utils/fintup-api'
import utils from '../utils/utils'

class FintupPrivateView extends FintupComponent {
  constructor (props) {
    super(props)
    SessionController.checkSession()
  }

  componentDidMount () {

    let obj = this.promiseUserData()

    if(obj.error){
      return obj.promise
    } else {
      return obj.promise.then(response => {
        let userData = response.data.userData
        let sessionUser = {
          id: userData.userId,
          name: utils.getUserFullName(userData),
          dni: userData.dni,
          email: userData.email
        }
        UserController.setUser(sessionUser)
      })
    }
  }

  promiseUserData(){
    let selectedDni = tGet('routeParams.selectedDni', '')(this.props)
    let sessionDni = tGet('user.dni', '')(UserController)
    let sessionEmail = tGet('user.email', '')(UserController)

    let promise, error = false
    if (selectedDni !== '' && selectedDni !== sessionDni) {
      promise = adminUserDetails({userDocument: selectedDni})
    }
    else if (sessionDni && sessionDni !== '') {
      promise = adminUserDetails({userDocument: sessionDni})
    }
    else if(sessionEmail && sessionEmail !== '') {
      promise = adminUserDetailsByEmail({userEmail: sessionEmail})
    }else{
      error = true
      promise = RSVP.resolve()
    }
    return {promise: promise, error: error}
  }
}
export default FintupPrivateView
