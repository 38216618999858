import FintupComponent from './FintupComponent'
import transverser from '../utils/transverser'
import config from '../config/config'
import utils from '../utils/utils'
import ReactGA from 'react-ga'

class FintupView extends FintupComponent {
  constructor (props) {
    super(props)
    this.isMobileView = utils.isMobileDevice()
  }

  getPath () {
    return transverser.get('route.path', '')(this.props)
  }

  componentWillMount () {
    utils.scrollToTop()
    if (config.googleAnalyticsEnabled) {
      let actualPath = this.getPath()
      if (actualPath) {
        ReactGA.pageview(actualPath)
      }
    }
  }
}
export default FintupView
