import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import './CopyRight.css'

class CopyRight extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateToday: new Date(),
    }
  }

  render() {
    return (
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="copyright">
                <FormattedMessage id="Copyright.derechos" /> {this.state.dateToday.getFullYear()} | <FormattedMessage id='Copyright.fintup' values={{ year: moment().format('YYYY') }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CopyRight
