import React from 'react'
import FintupComponent from '../FintupComponent'
import FintupButton from '../common/forms/FintupButton'
import navUtils from '../../core/navUtils'
import './BackButton.css'

class BackButton extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
      <div className="backButton">
        <FintupButton textKey="common.button" action={() => navUtils.goToInitUrl()}/>
      </div>
    )
  }
}

export default BackButton
