import React, { createRef } from 'react'
import FintupPrivateView from '../FintupPrivateView'
import { observer } from 'mobx-react'
import { doGetMaritalTypeList, adminHistoryAdviceSimulate, adminUserDetailsById, adminHistoryAdviceObjectiveSimulate, adminInvitationConfirmVoucher, historyAdviceObjectiveDelete, adminHistoryAdviceObjectiveCreate, adminHistoryAdviceCreate, adminNotificationDetail, portfolioAdviceList, adviceCategoriesList, adviceObjectivesList, adminHistoryAdviceList, adminHistoryAdviceHistoryCreate, adminPortfolioList, adminProductList, adminUserDetails } from '../../utils/fintup-api'
import './HistoryAdviceClientView.scss'
import utils from '../../utils/utils'
import FintupButton from '../common/forms/FintupButton'
import UserController from '../../core/UserMobx'
import OperationData from '../contribution/OperationData'
import { tGet } from '../../utils/transverser'
import InputTextLabel from '../common/forms/InputTextLabel'
import constants from '../../utils/constants'
import InputTextArea from '../common/forms/InputTextArea'
import { FormattedMessage, injectIntl } from 'react-intl'
import uniqid from 'uniqid'
import JoditEditor from "jodit-react";
import { getBooleanConfig } from '../../config/config'
import _ from 'lodash'
import SelectLabel from '../common/forms/SelectLabel'
import DatePicker from '../common/forms/DatePicker'
import ProductMobx from '../../core/ProductsMobx'
import RSVP from 'rsvp'
import moment from 'moment'


class HistoryAdviceClientView extends FintupPrivateView {
  /**
   *
   */
  constructor(props) {
    super(props)
    this.state = {
      segmentValue: "2",
      description: '', //new history
      proposal: '', //new history  
      birthdate: '',
      //new objective
      newObjective: {
        adviceCategoryId: 1, //only retirement at the moment
        investmentYears: 0, //loadData function is updated the value for userAdvice investmentYears
        userProducts: [],
        userContracts: []
      },
      userData: {
        userData: {},
        profile: {
          address: {},
          personalData: {},
          moneyLaundering: {}
        }
      },
      showPersonalData: false,
      showPlan: false,
      showHeritage: false,
      showObjectives: false,
      showNewObjective: false,
      showHistory: false,
      showNewEntry: true,
      showDescription: true,
      showProposal: true,
      userAdvice: {
        age: 0,
        retirementAge: getBooleanConfig('fintupAdviceRetirementAge'),
        lifeExpectancy: getBooleanConfig('fintupAdviceLifeExpectancy'),
      },
      editorDescription: React.createRef(null),
      editorProposal: React.createRef(null),
      configEditorDescription: {
        placeholder: this.props.intl.formatMessage({ id: "historyView.description" })
      },
      configEditorProposal: {
        placeholder: this.props.intl.formatMessage({ id: "historyView.proposal" })
      },
      temporaryHorizonList: [
        {
          labelText: '< 2 años',
          value: 1
        },
        {
          labelText: '2 a 5 años',
          value: 2
        },
        {
          labelText: '5 a 8 años',
          value: 3
        },
        {
          labelText: '8 a 10 años',
          value: 4
        },
        {
          labelText: '+10 años',
          value: 5
        }
      ],
      riskProfileList: [
        {
          labelText: '1',
          value: 1
        },
        {
          labelText: '2',
          value: 2
        },
        {
          labelText: '3',
          value: 3
        },
        {
          labelText: '4',
          value: 4
        },
        {
          labelText: '5',
          value: 5
        }
      ],
      regimeList: [
        {
          labelText: "Asalariado/a",
          value: "S"
        },
        {
          labelText: "Autónomo/a",
          value: "A"
        },
        {
          labelText: "Jubilado/a",
          value: "R"
        },
      ],
      typesObjectivesList: [],
      typesCategoriesList: [],
      portfolioList: [],
      productList: [],
      maritalTypeList: [],
      debounceDelayMillis: getBooleanConfig('userAdviceDebounceDelayMillis'),
    }
    //console.log("user advice data state: ", this.state.userAdvice)
    //console.log("DEBOUNCE DELAY MILLIS: ", this.state.debounceDelayMillis)
    this.debounceCalculate = _.debounce(this.simulateData.bind(this), this.state.debounceDelayMillis)
    this.debounceNewObjectiveCalculate = _.debounce(this.simulateNewObjectiveData.bind(this), this.state.debounceDelayMillis)
  }

  componentDidMount() {

    this.onChangeSegment()
    this.loadData()
  }

  addObjectiveRow() {
    //console.log("NEW OBJECTIVE ROW: ", this.state.newObjective)
    let objectivesList = this.state.userAdvice.userAdviceObjectiveList || []
    objectivesList.unshift(
      this.state.newObjective
    )
    return this.setStatePromise({ userAdvice: _.merge(this.state.userAdvice, { userAdviceObjectiveList: objectivesList }) })
      .then(() => {
        //clear objective
        return this.setStatePromise(
          {
            newObjective: {
              adviceCategoryId: 1, //only retirement at the moment
              investmentYears: this.state.userAdvice.investmentYears,
              userProducts: [],
              userContracts: []
            }
          })
      });

  }

  deleteObjectiveRow(index) {
    if (window.confirm("¿Estás seguro de querer eliminar el objetivo?")) {
      //REMOVE OBJECTIVE
      historyAdviceObjectiveDelete(this.state.userAdvice.userAdviceObjectiveList[index].id)
        .then((response) => {
          alert('¡Objetivo borrado correctamente!')
        })
        .then(() => {
          let items = this.state.userAdvice.userAdviceObjectiveList
          items.splice(index, 1)
          return this.setStatePromise({ userAdvice: _.merge(this.state.userAdvice, { userAdviceObjectiveList: items }) })
        })
        .catch(this.handleServiceError.bind(this))


    }
  }

  addProductRow() {
    let items = this.state.newObjective.userProducts || []
    items.unshift(
      {
        productId: 0,
        portfolioId: 0,
        initialContribution: '',
        mensualContribution: '',
        finalValueInversion: ''
      }
    )
    //console.log("ADD user product objective row: ", items)
    return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { userProducts: items }) });
  }

  deleteProductRow(index) {
    let items = this.state.newObjective.userProducts
    items.splice(index, 1)
    return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { userProducts: items }) });
  }

  addContractRow() {
    let items = this.state.newObjective.userContracts
    items.unshift({
      contractId: 0
    })
    //console.log("ADD user contract objective row: ", items)
    return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { userContracts: items }) });
  }


  deleteContractRow(index) {
    let items = this.state.newObjective.userContracts
    items.splice(index, 1)
    return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { userContracts: items }) });
  }

  loadData() {
    //console.log("LOAD DATA user: ", UserController.user.id)
    let data = {
      userId: UserController.user.id
    }
    let initPromise = ProductMobx.productList.length === 0 ? ProductMobx.loadProductList() : RSVP.resolve()
    return initPromise
      .then(() => adminUserDetailsById({ userId: UserController.user.id }))
      .then(response => {
        //console.log("user data: ", response.data)
        let responseData = response.data
        responseData.profile.personalData = tGet('data.profile.personalData')(response) || {}
        responseData.profile.moneyLaundering = tGet('data.profile.moneyLaundering', {})(response) || {}
        responseData.profile.address = tGet('data.profile.address', {})(response) || {}
        return this.setStatePromise({ userData: responseData })
      })
      .then(doGetMaritalTypeList)
      .then(response => {
        //console.log("RESPONSE maritalTypeList: ", response.data)
        return this.setStatePromise({ maritalTypeList: response.data.maritalTypes })
      })
      .then(portfolioAdviceList)
      .then(response => this.setStatePromise({ portfolioList: response.data }))
      .then(adminProductList)
      .then(response => this.setStatePromise({ productList: response.data }))
      .then(() => adminHistoryAdviceList(data))
      .then(response => {
        //console.log("LOAD DATA: ", response.data)
        //Default values
        response.data.retirementAge = response.data.retirementAge || this.state.userAdvice.retirementAge
        response.data.lifeExpectancy = response.data.lifeExpectancy || this.state.userAdvice.lifeExpectancy
        //console.log("response.data birthdate: ", response.data)
        return this.setStatePromise({ userAdvice: response.data ? response.data : {} })

      })
      .then(() => {
        //Default values new Objective
        this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { investmentYears: this.state.userAdvice.investmentYears }) });
      })
      .then(adviceObjectivesList)
      .then(response => {
        this.setStatePromise({ typesObjectivesList: response.data })
      })
      .then(adviceCategoriesList)
      .then(response => {
        console.log("CATEGORIES: ", response.data)
        this.setStatePromise({ typesCategoriesList: response.data })
      })
  }

  showAlert(alertData) {
    let message = this.props.intl.formatMessage({ id: alertData.textKey })
    alert(message)
  }

  /**
   * Save advice
   */
  saveAdvice() {
    //Validate
    let insert = true;

    if (utils.isEmpty(this.state.userAdvice.birthdate)) {
      //insert = false
      this.showAlert({ textKey: 'historyView.error.birthdate' })
      return false
    }

    if (insert) {

      //console.log("INSERTAR")

      const data = {
        // userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
        ...this.state.userAdvice,
        userId: UserController.user.id
      }
      //console.log("DATA TO INSERT: ", data)
      //Save history
      this.saveHistory() //Save line history
        .then((responseHistory) => {
          console.log("Response history, ", responseHistory)
          return adminHistoryAdviceCreate(data)
        }) //Save data advice*/
        .then((response) => {
          console.log("RESPONSE SAVE: ", response)
          alert('¡Datos guardados correctamente!')
        })
        .then(() => this.loadData())
        .catch(this.handleServiceError.bind(this))
    }
    /*else
      console.log("NO INSERTAR")*/
  }

  saveObjective() {

    //Validate
    let insert = true;

    if (utils.isEmpty(this.state.newObjective.investmentYears)) {
      //insert = false
      this.showAlert({ textKey: 'historyView.error.investmentYears' })
      return false
    }



    //CHeck products null
    let userProducts = this.state.newObjective.userProducts
    if (userProducts != null)
      this.state.newObjective.userProducts.map(item => {
        item.initialContribution = item.initialContribution == "" || item.initialContribution == null ? 0 : item.initialContribution
        item.mensualContribution = item.mensualContribution == "" || item.mensualContribution == null ? 0 : item.mensualContribution
        return item
      })

    if (insert) {
      //console.log("INSERTAR")

      const data = {
        // userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
        ...this.state.newObjective,
        userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
        userId: UserController.user.id,
      }
      //console.log("DATA TO INSERT: ", data)
      adminHistoryAdviceObjectiveCreate(data)
        .then((response) => {
          alert('¡Objetivo guardado correctamente!')
          //console.log("get create objective ok: ", response.data)
          //Get id
          return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { id: response.data.id }) });
        })
        .then(() => this.addObjectiveRow())
        //.then(() => this.loadData())
        .catch(this.handleServiceError.bind(this))
    }
    /*else
    console.log("NO INSERTAR")*/
  }

  saveHistory() {
    //Validate
    let insert = true;

    if (utils.isEmpty(this.state.description) && utils.isEmpty(this.state.proposal))
      insert = false

    if (insert) {
      const data = {
        userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
        userId: UserController.user.id,
        description: this.state.description,
        proposal: this.state.proposal,
      }
      //console.log("DATA TO INSERT: ", data)
      return adminHistoryAdviceHistoryCreate(data)
        .then((response) => {
          //alert('¡Entrada creada correctamente!')

          this.setStatePromise({ description: '', proposal: '' })
        })
        .catch(this.handleServiceError.bind(this))
    }
    else
      return RSVP.resolve()
    /*else
      console.log("NO INSERTAR")*/
  }

  simulateData() {
    const data = {
      ...this.state.userAdvice,
      userId: UserController.user.id
    }
    //console.log("simulate data", data)
    adminHistoryAdviceSimulate(data)
      .then(response => this.setStatePromise({ userAdvice: response.data }))
  }

  /**
   * Data general changes
   * @param {*} data 
   */
  onChange(data) {
    //console.log("data on change: ", data)
    this.commonHandleChange(data)
      .then(this.debounceCalculate.bind(this))
  }

  /**
   * Data general changes new objective
   * @param {*} data 
   */
  onChangeNewObjective(data) {
    //console.log("data on change: ", data)
    this.commonHandleChange(data)
      .then(() => {
        //Update investmentYears change
        return this.setStatePromise({ newObjective: _.merge(this.state.newObjective, { investmentYears: this.state.newObjective.adviceCategoryId == 1 ? this.state.userAdvice.investmentYears : this.state.newObjective.investmentYears }) })
      })
      .then(this.debounceNewObjectiveCalculate.bind(this))
  }

  simulateNewObjectiveData() {
    //console.log("simulate data")
    const data = {
      // userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
      ...this.state.newObjective,
      userAdviceId: this.state.userAdvice ? this.state.userAdvice.id : null,
      userId: UserController.user.id,
    }
    adminHistoryAdviceObjectiveSimulate(data)
      .then(response => this.setStatePromise({ newObjective: response.data }))
  }

  onChangeSegment() {
    const segments = document.querySelectorAll('ion-segment')
    this.changeSegments(this.state.segmentValue)
    for (let i = 0; i < segments.length; i++) {
      segments[i].addEventListener('ionChange', (ev) => {
        //console.log('Segment changed', ev.detail.value);
        let value = ev.detail.value
        this.setStatePromise({ segmentValue: value })
        this.changeSegments(value)
      })
    }
  }

  changeSegments(value) {
    switch (value) {
      case "1":
        this.showHidePersonalData(true)
        this.showHidePlan(false)
        this.showHideHeritage(false)
        this.showHideObjectives(false)
        this.showHideHistory(false)
        break;
      case "2":
        this.showHidePersonalData(false)
        this.showHidePlan(true)
        this.showHideHeritage(false)
        this.showHideObjectives(false)
        this.showHideHistory(false)
        break;
      case "3":
        this.showHidePersonalData(false)
        this.showHidePlan(false)
        this.showHideHeritage(true)
        this.showHideObjectives(false)
        this.showHideHistory(false)

        break;
      case "4":
        this.showHidePersonalData(false)
        this.showHidePlan(false)
        this.showHideHeritage(false)
        this.showHideObjectives(true)
        this.showHideHistory(false)
        break;
      case "5":
        this.showHidePersonalData(false)
        this.showHidePlan(false)
        this.showHideHeritage(false)
        this.showHideObjectives(false)
        this.showHideHistory(true)
        break;
    }
  }


  render() {
    return (
      <div className="historyView">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="title-page">Asesoramiento del usuario {UserController.user.id} - {UserController.user.dni} - {UserController.user.email}
              <FintupButton className="button-save-header margin-left" textKey="historyView.saveAdvice" size={constants.BUTTON_SIZE.LARGE} action={this.saveAdvice.bind(this)} /></h1>
          </div>
        </div>
        <div className="row">
          {this.state.showNewEntry ?
            (
              <div className="content-header col-xs-12 flexHorizontalCenter">
                <div className="col-sm-offset-1 col-sm-6 col-xs-12">
                  <div className="col-xs-12">
                    <div className="margin-bottom-xs">
                      <span id="notes" className="font-weight-bold"><FormattedMessage id="historyView.description" /></span>
                      &nbsp;(<a href="#notes" onClick={this.showHideDescription.bind(this)}>Ver/Ocultar notas</a>)
                    </div>
                    {
                      this.state.showDescription ?
                        (
                          <JoditEditor
                            ref={this.state.editorDescription}
                            value={this.state.description}
                            config={this.state.configEditorDescription}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={val => this.commonTextareaChange("description", val)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => { }}
                          />
                        ) : ''
                    }
                  </div>
                  <div className="col-xs-12 margin-top">
                    <div className="margin-bottom-xs">
                      <span id="proposal" className="font-weight-bold"><FormattedMessage id="historyView.proposal" /></span>
                      &nbsp;(<a href="#proposal" onClick={this.showHideProposal.bind(this)}>Ver/Ocultar propuesta</a>)
                  </div>
                    {
                      this.state.showProposal ?
                        (
                          <JoditEditor
                            ref={this.state.editorProposal}
                            value={this.state.proposal}
                            config={this.state.configEditorProposal}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={val => this.commonTextareaChange("proposal", val)} // preferred to use only this option to update the content for performance reasons
                            onChange={newContent => { }}
                          />
                        ) : ''
                    }
                  </div>
                </div>
                <div className="col-sm-3 col-xs-12 margin-top">
                  <SelectLabel className="col-xs-12" labelSize={3}
                    label="historyView.riskProfile" name="userAdvice.riskProfile" options={this.state.riskProfileList} selectedValue={this._getState('userAdvice.riskProfile', '')} callBackOnChange={this.commonHandleChange.bind(this)} />
                  <InputTextLabel className="col-xs-12" labelSize={3}
                    disabled={true}
                    label="historyView.savingsCapacity" name="userAdvice.savingsCapacity"
                    value={utils.formatPorcentage(this.state.userAdvice.savingsCapacity, 2) == "" ? 0 : utils.formatPorcentage(this.state.userAdvice.savingsCapacity, 2)} callBackOnChange={this.onChange.bind(this)} />
                  <InputTextLabel className="col-xs-12" labelSize={3}
                    disabled={true}
                    label="historyView.expenseMonthly" name="userAdvice.expenseMonthly"
                    value={utils.formatNumber(this.state.userAdvice.expenseMonthly)} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12" labelSize={3}
                    disabled={true}
                    label="historyView.financialIndependence" name="userAdvice.financialIndependence"
                    value={utils.formatNumber(this.state.userAdvice.financialIndependence)} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12" labelSize={3}
                    disabled={true}
                    label="historyView.safetyCushion" name="userAdvice.safetyCushion"
                    value={utils.formatNumber(utils.formatDecimal(this.state.userAdvice.safetyCushion, 2))} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12" labelSize={3}
                    disabled={true}
                    label="historyView.retirementIncomeMonthly" name="userAdvice.retirementIncomeMonthly"
                    value={utils.formatNumber(utils.formatDecimal(this.state.userAdvice.retirementIncomeMonthly, 2))} callBackOnChange={this.onChange.bind(this)} />

                </div>
                {/*<div className="col-xs-12 margin-top">
                  <FintupButton className="col-xs-12" textKey="notificationDetail.create" size={constants.BUTTON_SIZE.LARGE} action={this.saveHistory.bind(this)} />
                </div>*/}
              </div>) : ''}
        </div>
        <div className="row">
          <div className="col-sm-offset-2 col-sm-8 col-xs-12">
            <ion-segment scrollable value={this.state.segmentValue} mode="ios">
              <ion-segment-button value="1">
                <ion-label>Datos personales</ion-label>
              </ion-segment-button>
              <ion-segment-button value="2">
                <ion-label>Unidad familiar</ion-label>
              </ion-segment-button>
              <ion-segment-button value="3">
                <ion-label>Datos patrimoniales</ion-label>
              </ion-segment-button>
              <ion-segment-button value="4">
                <ion-label>Objetivos</ion-label>
              </ion-segment-button>
              <ion-segment-button value="5">
                <ion-label>Historial</ion-label>
              </ion-segment-button>
            </ion-segment>
          </div>
        </div>
        <div className="row row-block">
          {this.state.showPersonalData ?
            (
              <div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">

                  <div className="col-xs-12 margin-bottom">
                    <span className="font-size-normal font-weight-normal"><a href="/usuarios/modificar" target="_blank">Ir a modificar perfil</a></span>
                  </div>

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="newUser.name" name="userData.name"
                    value={this.state.userData.userData.name} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="newUser.surname" name="userData.surname"
                    value={this.state.userData.userData.surname} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="newUser.email" name="userData.email"
                    value={this.state.userData.userData.email} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="newUser.dni" name="userData.profile.dni"
                    value={this.state.userData.userData.dni} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="newUser.phone" pasteTransform={utils.removeBlanks}
                    name="userData.profile.personalData.phone" value={this.state.userData.profile.personalData.phone} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <SelectLabel className="col-xs-12 col-sm-4" labelSize={3} disabled={true}
                    label="ContractPersonalData.maritalType"
                    name="userData.profile.personalData.maritalTypeId" options={this.maritalTypeOptions} selectedValue={this._getState('userData.profile.personalData.maritalTypeId', '')} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                    labelSize={3} label="ContractPersonalData.economicActivity" pasteTransform={utils.removeBlanks}
                    name="userData.profile.moneyLaundering.economicActivity" value={this.state.userData.profile.moneyLaundering.economicActivity} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <div className="row row-block margin-top">
                    <div className="col-xs-12 margin-bottom">
                      <h3 id="createobjective" className="subtitle-page"><FormattedMessage id="historyView.addressTitle" />
                        &nbsp; <span className="font-size-normal font-weight-normal"></span></h3>
                    </div>
                    <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                      labelSize={3} label="newUser.address" pasteTransform={utils.removeBlanks}
                      name="userData.profile.address.address" value={this.state.userData.profile.address.address} callBackOnChange={this.commonHandleChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                      labelSize={3} label="newUser.postalCode" pasteTransform={utils.removeBlanks}
                      name="userData.profile.address.postalCode" value={this.state.userData.profile.address.postalCode} callBackOnChange={this.commonHandleChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                      labelSize={3} label="newUser.province" pasteTransform={utils.removeBlanks}
                      name="userData.profile.address.province" value={this.state.userData.profile.address.province} callBackOnChange={this.commonHandleChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" disabled={true}
                      labelSize={3} label="newUser.city" pasteTransform={utils.removeBlanks}
                      name="userData.profile.address.city" value={this.state.userData.profile.address.city} callBackOnChange={this.commonHandleChange.bind(this)} />

                  </div>
                </div>
                {/*<div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <FintupButton textKey="historyView.saveAdvice" size={constants.BUTTON_SIZE.LARGE} action={this.saveAdvice.bind(this)} />
                </div>*/}
              </div>) : ''}
          {this.state.showPlan ?
            (
              <div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.birthdate" name="userAdvice.birthdate"
                    value={this.state.userAdvice.birthdate} callBackOnChange={this.onChange.bind(this)} />
                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    disabled={true}
                    label="historyView.age" name="userAdvice.age"
                    value={this.state.userAdvice.age} callBackOnChange={this.onChange.bind(this)} />
                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.retirementAge" name="userAdvice.retirementAge"
                    value={this.state.userAdvice.retirementAge} callBackOnChange={this.onChange.bind(this)} />
                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    disabled={true}
                    label="historyView.investmentYears" name="userAdvice.investmentYears"
                    value={this.state.userAdvice.investmentYears} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.lifeExpectancy" name="userAdvice.lifeExpectancy"
                    value={this.state.userAdvice.lifeExpectancy} callBackOnChange={this.onChange.bind(this)} />

                  <SelectLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.temporaryHorizon" name="userAdvice.temporaryHorizon" options={this.state.temporaryHorizonList} selectedValue={this._getState('userAdvice.temporaryHorizon', '')} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.company" name="userAdvice.company"
                    value={this.state.userAdvice.company} callBackOnChange={this.onChange.bind(this)} />

                  <SelectLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.regime" name="userAdvice.regime" options={this.state.regimeList} selectedValue={this._getState('userAdvice.regime', '')} callBackOnChange={this.commonHandleChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.netIncomeMonthly" name="userAdvice.netIncomeMonthly"
                    value={this.state.userAdvice.netIncomeMonthly} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.netSavingsMonthly" name="userAdvice.netSavingsMonthly"
                    value={this.state.userAdvice.netSavingsMonthly} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.numberPayments" name="userAdvice.numberPayments"
                    value={this.state.userAdvice.numberPayments} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.annualBonus" name="userAdvice.annualBonus"
                    value={this.state.userAdvice.annualBonus} callBackOnChange={this.onChange.bind(this)} />

                  <div className="row row-block margin-top">
                    <div className="col-xs-12 margin-bottom">
                      <h3 id="createobjective" className="subtitle-page"><FormattedMessage id="historyView.partnerTitle" />
                        &nbsp; <span className="font-size-normal font-weight-normal"></span></h3>
                    </div>
                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.agePartner" name="userAdvice.agePartner"
                      value={this.state.userAdvice.agePartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.economicActivityPartner" name="userAdvice.economicActivityPartner"
                      value={this.state.userAdvice.economicActivityPartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.companyPartner" name="userAdvice.companyPartner"
                      value={this.state.userAdvice.companyPartner} callBackOnChange={this.onChange.bind(this)} />

                    <SelectLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.regimePartner" name="userAdvice.regimePartner" options={this.state.regimeList} selectedValue={this._getState('userAdvice.regimePartner', '')} callBackOnChange={this.commonHandleChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.netIncomeMonthlyPartner" name="userAdvice.netIncomeMonthlyPartner"
                      value={this.state.userAdvice.netIncomeMonthlyPartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.netSavingsMonthlyPartner" name="userAdvice.netSavingsMonthlyPartner"
                      value={this.state.userAdvice.netSavingsMonthlyPartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.numberPaymentsPartner" name="userAdvice.numberPaymentsPartner"
                      value={this.state.userAdvice.numberPaymentsPartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.annualBonusPartner" name="userAdvice.annualBonusPartner"
                      value={this.state.userAdvice.annualBonusPartner} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      disabled={true}
                      label="historyView.expenseMonthly" name="userAdvice.expenseMonthlyPartner"
                      value={utils.formatNumber(this.state.userAdvice.expenseMonthlyPartner)} callBackOnChange={this.onChange.bind(this)} />

                  </div>
                  <div className="row row-block margin-top">
                    <div className="col-xs-12 margin-bottom">
                      <h3 id="createobjective" className="subtitle-page"><FormattedMessage id="historyView.sonsTitle" />
                        &nbsp; <span className="font-size-normal font-weight-normal"></span></h3>
                    </div>
                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.numberSons" name="userAdvice.numberSons"
                      value={this.state.userAdvice.numberSons} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.ageSons" name="userAdvice.ageSons"
                      value={this.state.userAdvice.ageSons} callBackOnChange={this.onChange.bind(this)} />

                    <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                      label="historyView.ageYoungSon" name="userAdvice.ageYoungSon"
                      value={this.state.userAdvice.ageYoungSon} callBackOnChange={this.onChange.bind(this)} />
                  </div>
                </div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <FintupButton textKey="historyView.saveAdvice" size={constants.BUTTON_SIZE.LARGE} action={this.saveAdvice.bind(this)} />
                </div>
              </div>) : ''}
          {this.state.showHeritage ?
            (
              <div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyAccount" name="userAdvice.moneyAccount"
                    value={this.state.userAdvice.moneyAccount} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyDeposit" name="userAdvice.moneyDeposit"
                    value={this.state.userAdvice.moneyDeposit} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyPensionPlan" name="userAdvice.moneyPensionPlan"
                    value={this.state.userAdvice.moneyPensionPlan} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyFunds" name="userAdvice.moneyFunds"
                    value={this.state.userAdvice.moneyFunds} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyCompanyPortfolio" name="userAdvice.moneyCompanyPortfolio"
                    value={this.state.userAdvice.moneyCompanyPortfolio} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyCompanyPensionPlan" name="userAdvice.moneyCompanyPensionPlan"
                    value={this.state.userAdvice.moneyCompanyPensionPlan} callBackOnChange={this.onChange.bind(this)} />

                  <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                    label="historyView.moneyCompanyGuaranteedSavings" name="userAdvice.moneyCompanyGuaranteedSavings"
                    value={this.state.userAdvice.moneyCompanyGuaranteedSavings} callBackOnChange={this.onChange.bind(this)} />

                </div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <FintupButton textKey="historyView.saveAdvice" size={constants.BUTTON_SIZE.LARGE} action={this.saveAdvice.bind(this)} />
                </div>
              </div>) : ''}
          {this.state.showObjectives ?
            (
              <div>
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  <h3 id="createobjective" className="subtitle-page"><FormattedMessage id="historyView.createTitleObjective" />
                    &nbsp; <span className="font-size-normal font-weight-normal">(<a href="#createobjective" onClick={this.showHideNewObjective.bind(this)}>Ver/Ocultar crear objetivo</a>)</span></h3>
                </div>
                {this.state.showNewObjective ?
                  (
                    <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                      <div className="col-xs-12">
                        <SelectLabel className="col-xs-12 col-sm-4" labelSize={3}
                          label="historyView.typeCategory" name="newObjective.adviceCategoryId" options={this.typeCategoriesOptions}
                          selectedValue={this.state.newObjective.adviceCategoryId} callBackOnChange={this.onChangeNewObjective.bind(this)} />
                        <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                          label="historyView.descriptionObjective" name="newObjective.description"
                          value={this.state.newObjective.description} callBackOnChange={this.commonHandleChange.bind(this)} />

                        <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3} disabled={this.state.newObjective.adviceCategoryId == 1}
                          label="historyView.investmentYearsObjective" name="newObjective.investmentYears"
                          value={this.state.newObjective.adviceCategoryId == 1 ? this.state.userAdvice.investmentYears : this.state.newObjective.investmentYears} callBackOnChange={this.onChangeNewObjective.bind(this)} />
                        <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
                          label="historyView.targetMoney" name="newObjective.targetMoney"
                          value={this.state.newObjective.targetMoney}
                          callBackOnChange={this.onChangeNewObjective.bind(this)} />
                        <InputTextLabel className="col-xs-12 col-sm-6" labelSize={3} disabled={true}
                          label="historyView.sumValueProducts" name="newObjective.sumValueProducts"
                          value={utils.formatNumber(this.state.newObjective.sumValueProducts)} callBackOnChange={this.commonHandleChange.bind(this)} />
                        <InputTextLabel className="col-xs-12 col-sm-6" labelSize={3} disabled={true}
                          label="historyView.sumValueContracts" name="newObjective.sumValueContracts"
                          value={utils.formatNumber(this.state.newObjective.sumValueContracts)} callBackOnChange={this.commonHandleChange.bind(this)} />

                        <FintupButton className="col-xs-12" buttonClassName="btnAddRow" textKey="historyView.createModelPorfolio" action={this.addProductRow.bind(this)} />
                        {this.renderModelProducts(this.state.newObjective, 'newObjective', true)}
                        <FintupButton className="col-xs-12" buttonClassName="btnAddRow" textKey="historyView.createModelContract" action={this.addContractRow.bind(this)} />
                        {this.renderModelContracts(this.state.newObjective, 'newObjective', true)}
                      </div>
                      <div className="col-xs-12 ">
                        <FintupButton className="col-xs-12" textKey="historyView.createObjective" size={constants.BUTTON_SIZE.LARGE} action={this.saveObjective.bind(this)} />
                      </div>
                    </div>) : ''}
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  {this.renderObjectives}
                </div>
              </div>
            ) : ''}
          {this.state.showHistory ? (
            <div>
              <div className="row row-block">
                <div className="col-sm-offset-2 col-sm-8 col-xs-12 margin-top">
                  {this.renderList}
                </div>
              </div>
            </div>
          )
            : ''}

        </div >
      </div >

    )
  }

  get renderObjectives() {
    return this.state.userAdvice && this.state.userAdvice.userAdviceObjectiveList ? this.state.userAdvice.userAdviceObjectiveList.map((element, index) => {
      let adviceCategoryIdName = `userAdvice.userAdviceObjectiveList[${index}].adviceCategoryId`
      let descriptionName = `userAdvice.userAdviceObjectiveList[${index}].description`
      let investmentYearsName = `userAdvice.userAdviceObjectiveList[${index}].investmentYears`
      let targetMoneyName = `userAdvice.userAdviceObjectiveList[${index}].targetMoney`
      return (
        <div key={`historyClientObjective-${uniqid()}`} className="card margin-top">
          <div className="row row-block">
            <div className="row">
              <div className="col-xs-11">
                <span className="title-subsection margin-bottom-xs"><FormattedMessage id="historyView.objective" /> {index + 1}</span>
              </div>
              <div className="col-xs-1 buttonDeleteObjective" onClick={() => this.deleteObjectiveRow(index)}>
                <span>X</span>
              </div>
            </div>

            <SelectLabel className="col-xs-12 col-sm-4" labelSize={3} disabled={true}
              label="historyView.typeCategory" name={adviceCategoryIdName} options={this.typeCategoriesOptions} selectedValue={element.adviceCategoryId} callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3} disabled={true}
              label="historyView.descriptionObjective" name={descriptionName}
              value={element.description} callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3} disabled={true}
              label="historyView.investmentYearsObjective" name={investmentYearsName}
              value={element.investmentYears} callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel className="col-xs-12 col-sm-4" labelSize={3}
              label="historyView.targetMoney" name={targetMoneyName} disabled={true}
              value={utils.formatNumber(element.targetMoney)}
              callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel className="col-xs-12 col-sm-6 margin-top" labelSize={3} disabled={true}
              label="historyView.sumValueProducts" name="newObjective.sumValueProducts"
              value={utils.formatNumber(element.sumValueProducts)} callBackOnChange={this.commonHandleChange.bind(this)} />
            <InputTextLabel className="col-xs-12 col-sm-6 margin-top" labelSize={3} disabled={true}
              label="historyView.sumValueContracts" name="newObjective.sumValueContracts"
              value={utils.formatNumber(element.sumValueContracts)} callBackOnChange={this.commonHandleChange.bind(this)} />

            {this.renderModelProducts(element, `userAdvice.userAdviceObjectiveList[${index}]`)}
            {this.renderModelContracts(element, `userAdvice.userAdviceObjectiveList[${index}]`)}

          </div>
        </div>
      )
    }) : ''
  }

  //Associated to objective
  renderModelProducts(objective, nameObject, isNew = false) {
    //console.log("renderModelProducts objective: ", objective)
    return (
      <div className="col-xs-12">
        {objective && objective.userProducts ? objective.userProducts.map((element, index) => {
          let productIdName = `${nameObject}.userProducts[${index}].productId`
          let portfolioIdName = `${nameObject}.userProducts[${index}].portfolioId`
          let initialContributionName = `${nameObject}.userProducts[${index}].initialContribution`
          let mensualContributionName = `${nameObject}.userProducts[${index}].mensualContribution`
          let finalValueInversionName = `${nameObject}.userProducts[${index}].finalValueInversion`
          //console.log("Name object: ", nameObject)
          //console.log("Name object userproductss: ", nameObject.userProducts)

          /*console.log("productIdName: ", productIdName)
          console.log("portfolioIdName: ", portfolioIdName)
          console.log("initialContributionName: ", initialContributionName)
          console.log("mensualContributionName: ", mensualContributionName)
          console.log("finalValueInversionName: ", finalValueInversionName)*/
          //console.log("ELEMENT: ", element)
          return (
            <div className="margin-top">
              <div className="row row-block">
                {/*<div className="col-xs-10">
                  <span className="title-subsection margin-bottom-xs"><FormattedMessage id="historyView.modelPortfolio" /> {index + 1}</span>
                  </div>*/}
                <div>
                  <SelectLabel disabled={!isNew} className="col-xs-6 col-sm-3" labelSize={3}
                    label="historyView.modelPortfolio" name={portfolioIdName} options={this.portfolioOptions} selectedValue={element.portfolioId}
                    callBackOnChange={isNew ? this.onChangeNewObjective.bind(this) : this.commonHandleChange.bind(this)} />
                  <InputTextLabel disabled={!isNew} className="col-xs-6 col-sm-3" labelSize={3}
                    label="historyView.initialContribution" name={initialContributionName}
                    value={element.initialContribution}
                    callBackOnChange={isNew ? this.onChangeNewObjective.bind(this) : this.commonHandleChange.bind(this)} />
                  <InputTextLabel disabled={!isNew} className="col-xs-6 col-sm-3" labelSize={3}
                    value={element.mensualContribution}
                    label="historyView.mensualContribution" name={mensualContributionName}
                    callBackOnChange={isNew ? this.onChangeNewObjective.bind(this) : this.commonHandleChange.bind(this)} />
                  <InputTextLabel disabled={true} className="col-xs-6 col-sm-2" labelSize={3}
                    label="historyView.finalValueInversion" name={finalValueInversionName}
                    value={utils.formatNumber(element.finalValueInversion)}
                    callBackOnChange={isNew ? this.onChangeNewObjective.bind(this) : this.commonHandleChange.bind(this)} />
                  {
                    isNew ?
                      (
                        <div className="col-xs-6 col-sm-1 buttonDeleteRow" onClick={() => this.deleteProductRow(index)}>
                          <span>X</span>
                        </div>
                      ) : ''
                  }

                </div>

              </div>
            </div>
          )
        }) : ''
        }
      </div>
    )

  }

  //Associated to objective
  renderModelContracts(objective, nameObject, isNew = false) {
    return (
      <div className="col-xs-12">
        {objective && objective.userContracts ? objective.userContracts.map((element, index) => {
          let contractIdName = `${nameObject}.userContracts[${index}].contractId`
          //console.log("ELEMENT: ", element)
          return (
            <div className={isNew ? 'margin-top' : ""}>
              <div className="row row-block">
                {/*<div className="col-xs-10">
                  <span className="title-subsection margin-bottom-xs"><FormattedMessage id="historyView.modelPortfolio" /> {index + 1}</span>
                  </div>*/}
                <div>
                  <SelectLabel disabled={!isNew} className="col-xs-6 col-sm-6" labelSize={3}
                    label="historyView.contracts" name={contractIdName} options={this.contractOptions}
                    selectedValue={element.contractId}
                    callBackOnChange={isNew ? this.onChangeNewObjective.bind(this) : this.commonHandleChange.bind(this)} />
                  {
                    isNew ?
                      (
                        <div className="col-xs-6 col-sm-1 buttonDeleteRow" onClick={() => this.deleteContractRow(index)}>
                          <span>X</span>
                        </div>
                      ) : ''
                  }

                </div>

              </div>
            </div>
          )
        }) : ''
        }
      </div>
    )
  }

  get renderList() {
    return this.state.userAdvice && this.state.userAdvice.userAdviceHistoryList ? this.state.userAdvice.userAdviceHistoryList.map(element => {
      return (
        <div key={`historyClient-${uniqid()}`} className="card margin-top">
          <div className="row">
            <div className="col-xs-12 font-weight-bold">{element.userAdminId.name + " - " + utils.formatTimestamp(element.creationDate)}</div>
          </div>
          <div className="row" >
            <div className="col-xs-6 overflow-break-word">
              <div className="text-decoration-underline font-weight-bold margin-bottom-xs"><FormattedMessage id="historyView.description" /></div>
              <div dangerouslySetInnerHTML={{ __html: element.description }} /></div>
            <div className="col-xs-6 overflow-break-word">
              <div className="text-decoration-underline font-weight-bold margin-bottom-xs"><FormattedMessage id="historyView.proposal" /></div>
              <div dangerouslySetInnerHTML={{ __html: element.proposal }} />
            </div>
          </div>

        </div>

      )
    }) : ''
  }

  get typeObjectivesOptions() {
    //List types objectives
    let typesObjectivesMapped = this.state.typesObjectivesList
    let typesObjectivesList = []
    if (typesObjectivesMapped != null) {
      typesObjectivesMapped.map(item => {
        typesObjectivesList.push({
          labelText: item.name,
          value: item.id
        })
      })
    }
    return typesObjectivesList
  }

  get typeCategoriesOptions() {
    //List types objectives
    let typesCategoriesMapped = this.state.typesCategoriesList
    let typesCategoriesList = []
    if (typesCategoriesMapped != null) {
      typesCategoriesMapped.map(item => {
        typesCategoriesList.push({
          labelText: item.name,
          value: item.id
        })
      })
    }
    return typesCategoriesList
  }

  get productOptions() {
    let productsMaped = this.state.productList
      .map(product => {
        return {
          labelText: `${product.id} - ${product.nameFintup}`,
          value: product.id
        }
      })
      .sort((a, b) => a.nameFintup - b.nameFintup)
    return productsMaped
  }

  get portfolioOptions() {
    let portfolioMaped = this.state.portfolioList
      .map(portfolio => {
        return {
          labelText: `${portfolio.name} - ${utils.formatPorcentage(portfolio.profitabilityPercentage * 100)}`,
          value: portfolio.id
        }
      })
      .sort((a, b) => a.value - b.value)
    return portfolioMaped
  }

  get maritalTypeOptions() {
    let maritalTypeOptions = this.state.maritalTypeList.map((maritalType) => {
      return {
        labelText: maritalType.text,
        value: maritalType.value
      }
    })
    return maritalTypeOptions
  }

  get contractOptions() {
    let contractMaped = ProductMobx.productList
      .map(contract => {
        let alias = contract.alias != null ? ' (' + contract.alias + ')' : ''
        let valueAmount = contract.value
        let valueLastPosition = utils.formatCurrency({ amount: valueAmount ? valueAmount.ammount : 0, currency: valueAmount ? valueAmount.currency : '€' })
        let getValueLastPosition = contract.value != null ? ' - ' + valueLastPosition : ''
        return {
          labelText: `${contract.contractId} - ${contract.portfolioName || contract.productName}${alias}${getValueLastPosition}`,
          value: contract.contractId,
          lastPositionValue: valueAmount ? valueAmount.ammount : 0
        }
      })
      .sort((a, b) => a.value - b.value)
    return contractMaped
  }

  showHidePersonalData(value) {
    this.setStatePromise({ showPersonalData: value })
  }

  showHidePlan(value) {
    this.setStatePromise({ showPlan: value })
  }

  showHideHeritage(value) {
    this.setStatePromise({ showHeritage: value })
  }

  showHideObjectives(value) {
    this.setStatePromise({ showObjectives: value })
  }

  showHideHistory(value) {
    this.setStatePromise({ showHistory: value })
  }

  showHideNewObjective() {
    this.setStatePromise({ showNewObjective: !this.state.showNewObjective })
  }

  showHideNewEntry() {
    this.setStatePromise({ showNewEntry: !this.state.showNewEntry })
  }

  showHideDescription() {
    this.setStatePromise({ showDescription: !this.state.showDescription })
  }

  showHideProposal() {
    this.setStatePromise({ showProposal: !this.state.showProposal })
  }
}

export default injectIntl(observer(HistoryAdviceClientView))
