import { extendObservable } from 'mobx'
import UserController from './UserMobx'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import RSVP from 'rsvp'
import urls from '../utils/urls'
import config from '../config/config'
import navUtils from '../core/navUtils'
import classNames from 'classnames'

class SessionMobx {
  constructor () {
    extendObservable(this, {
      user: extendObservable({}),
      token: '',
      oneTimePassword: false,
      emulateSession: false,
      isFullScreen: false,
      showFooter: true,
      isWidget: false,
      partnerSession: false,
      partnerType: null,
      scriptsLoaded: {
        calendly: false
      },
      unloggedUrl: null
    })
  }

  isSessionExpired () {
    let data = sessionStorage.getItem('fintupLoginMessage')
    return data !== null
  }

  getSessionExpiredMessage () {
    let data = sessionStorage.getItem('fintupLoginMessage')
    sessionStorage.removeItem('fintupLoginMessage')
    return JSON.parse(data)
  }

  sessionExpired () {
    let isEmulated = this.isEmulatedSession
    SessionController.destroySession()
    let loginMessage = {
      code: 'SESSIONEXPIRED',
      message: 'Su sesión ha expirado, es necesario hacer login de nuevo'
    }
    sessionStorage.setItem('fintupLoginMessage', JSON.stringify(loginMessage))
    navUtils.logoutRedirect(isEmulated)
  }

  get getShowFooter () {
    return this.showFooter
  }

  get appContainerClass () {
    return classNames(
      this.isFullScreen ? 'fintupFullContainer' : 'fintupHeaderContainer',
      {'fintupFullWidget': this.isWidget}
    )
  }

  get isEmulatedSession () {
    return this.emulateSession
  }

  checkSession () {
    if (!config.needLogin) {
      this.restoreSessionDateFromStorage()
      return RSVP.resolve()
    } else {
      if (!this.isLogged()) {
        let location = browserHistory.getCurrentLocation()
        this.unloggedUrl = location.pathname
        navUtils.goToInternal(urls.internal.login)
        return RSVP.reject()
      } else if (this.oneTimePassword) {
        navUtils.goToInternal(urls.internal.changePassword)
        return RSVP.reject()
      } else {
        return RSVP.resolve()
      }
    }
  }

  restoreSessionDateFromStorage () {
    let data = sessionStorage.getItem('fintupDataBackoffice')
    if (data && data !== 'null') {
      let dataObj = JSON.parse(data)
      this.user = dataObj.user
      this.token = dataObj.token
      this.partnerType = dataObj.partnerType
      this.oneTimePassword = dataObj.oneTimePassword
      this.emulateSession = dataObj.emulateSession
      this.partnerSession = dataObj.partnerSession
    }
    UserController.restoreUser()
  }

  isLogged () {
    if (_.isEmpty(this.token)) {
      this.restoreSessionDateFromStorage()
    }
    return !_.isEmpty(this.token)
  }

  destroySession () {
    this.user = {}
    this.token = ''
    this.oneTimePassword = false
    this.emulateSession = false
    this.partnerSession = false
    this.partnerType = null
    sessionStorage.setItem('fintupDataBackoffice', null)
  }

  setSession (sessionData) {
    this.user = sessionData.user
    this.token = sessionData.token
    this.oneTimePassword = sessionData.oneTimePassword
    this.emulateSession = sessionData.emulateSession
    this.partnerSession = sessionData.partnerSession
    this.partnerType = sessionData.partnerType
    sessionStorage.setItem('fintupDataBackoffice', JSON.stringify(this))
  }

  getLoginUrl () {
    let loginUrl = this.oneTimePassword ? urls.internal.changePassword : urls.internal.initUrl
    return loginUrl
  }

  redirectToUnloggedUrl () {
    if (this.unloggedUrl !== null) {
      let goToUnloggedUrl = this.unloggedUrl
      this.unloggedUrl = null
      navUtils.goToInternal(goToUnloggedUrl)
    } else {
      this.redirectToApp()
    }
  }

  redirectToApp () {
    let goToUrl = this.getLoginUrl()
    navUtils.goToInternal(goToUrl)
  }
}
var SessionController = new SessionMobx()
export default SessionController
