import { extendObservable } from 'mobx'
import { adminContractList } from '../utils/fintup-api'
import RSVP from 'rsvp'
import UserController from './UserMobx'

/**
 * Crea una sesion con los productos del usuario
 * updateRefresh: Realiza un 'conteo' con las actualizaciones que se realizan sobre los productos del usuario
 * updateProductAlias: Realiza la actualizacion de un alias de producto
 * setGlobalPositionData: Establece la posicion global del usuario
 * initProducts: Inicializa los productos de la posicion global
 * getProductsByProductId: Obtiene el listado de productos filtrados por un productId concreto
 * existsProductsByProductId: Indican si existen productos de una categoria concreta
 * destroyProducts: Destruye la sesion de productos
*/
class ProductsController {
  constructor () {
    extendObservable(this, {
      refreshField: 0,
      productsData: extendObservable({}),
      productList: extendObservable([])
    })
  }

  loadProductList (userId = UserController.user.id) {
    return adminContractList({userId: userId})
      .then(response => {
        this.productList = response.data
        this.refreshField++
        return RSVP.resolve()
      })
      .catch(error => {
        console.log(error)
        alert('No se han podido cargar los productos del ususario')
        return RSVP.reject()
      })
  }

  get productList () {
    return this.productList
  }

  resetProducts () {
    this.productsData = {}
    this.productList = []
    this.refreshField++
  }
}
var ProductMobx = new ProductsController()
export default ProductMobx
