import React, { Component } from 'react'
import Alert from '../../components/common/Alert'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import './SingleFormContainer.css'
import transverser from '../../utils/transverser';

/*
  title: Texto a mostrar como titulo del contendor
  titleKey: Clave multiidioma a mostrar como titulo del contenedor en caso de querer que la traducción la haga el propio componente

  legend: Leyenda a mostrar bajo el titulo del contenedor
  legendKey: Clave multiidioma a mostrar

  className: Clases que se añaden al contendor ademas de singleFormContainer mainContainer
*/
class SingleFormContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  get title () {
    return this.props.title ? this.props.title : this.props.intl.formatMessage({ id: this.props.titleKey })
  }

  get legend () {
    return this.props.legend
      ? this.props.legend
      : this.props.legendKey
        ? this.props.intl.formatMessage({ id: this.props.legendKey })
        : ''
  }

  get componentClasses () {
    return classNames('singleFormContainer mainContainer', this.props.className)
  }

  get showAlert () {
    return transverser.get('alert.text', null)(this.props) !== null
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <div className="row">
          <div className="form-container">
            <Alert text={this.props.alertText} show={this.showAlert} type={this.props.alertType}/>
            <h2 className="formTitle">
              {this.title}
            </h2>
            <p className="formLegend">
              {this.legend}
            </p>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(SingleFormContainer)
