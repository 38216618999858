let config = {
  hotConfigUrl: '/json/externalConfig_test.json',
  needLogin: true,
  apiBaseUrl: 'https://spreapi.fintup.com',
  cdnBaseUrl: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com',
  showMap: true,
  cdnEnviroment: 'test',
  googleAnalytics: 'UA-115030746-2',
  googleAnalyticsOptions: {
    cookieDomain: 'none'
  }
}

export default config
