import React from 'react'
import SingleFormContainer from '../common/SingleFormContainer'
import SocialLogin from '../common/SocialLogin'
import InputTextLabel from '../common/forms/InputTextLabel'
import FintupButton from '../common/forms/FintupButton'
import FintupView from '../FintupView'
import { FormattedMessage, injectIntl } from 'react-intl'
import { loginBackOfficePost } from '../../utils/fintup-api'
import SessionController from '../../core/SessionMobx'
import RSVP from 'rsvp'
import _ from 'lodash'
import './Home.css'
import constants from '../../utils/constants'

class Home extends FintupView {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    if (SessionController.isSessionExpired()) {
      let sessionExpiredData = SessionController.getSessionExpiredMessage()
      let sessionExpiredKey = sessionExpiredData.code
      let alertMessage = this.props.intl.formatMessage({ id: `Login.validation.${sessionExpiredKey}` })
      alert(alertMessage)
    }
  }

  doLogin () {
    if (_.isUndefined(this.state.username) || this.state.username === '' || _.isUndefined(this.state.password) || this.state.password === '') {
      this.setStatePromise({ alertText: <FormattedMessage id="Login.formValidate" /> })
      return
    }
    let user = {
      username: this.state.username,
      password: this.state.password
    }
    this.setStatePromise({alertText: ''})
      .then(() => loginBackOfficePost(user))
      .then(this.responseSuccessLogin.bind(this))
      .catch(this.responseErrorLogin.bind(this))
  }

  showAlert (data) {
    this.setStatePromise({ alertText: <FormattedMessage id={data.textKey} /> })
  }

  errorLogin (result) {
    this.setStatePromise({ alertText: result.message })
  }

  responseSuccessLogin (response) {
    SessionController.setSession({
      user: response.data.user,
      token: response.data.token,
      oneTimePassword: response.data.oneTimePassword,
      emulateSession: false,
      partnerSession: false
    })
    this.setState()
    SessionController.redirectToUnloggedUrl()
    return RSVP.resolve()
  }

  responseErrorLogin (response) {
    this.errorLogin(response)
    return RSVP.reject()
  }

  render () {
    return (
      <div className="home">
        <SingleFormContainer titleKey="home.enter" legendKey="home.social">
          <SocialLogin showAlert={this.showAlert.bind(this)} successLogin={this.responseSuccessLogin.bind(this)} errorLogin={this.responseErrorLogin.bind(this)}/>
          <div className="separator"><hr/></div>
          <div className="separatorCenter"><FormattedMessage id="home.o" /></div>
          <div className="separator"><hr/></div>
          <InputTextLabel placeholder="home.email" name="username" callBackOnChange={this.commonCheckboxChange.bind(this)} />
          <InputTextLabel type="password" placeholder="home.password" name="password" callBackOnChange={this.commonCheckboxChange.bind(this)}/>
          <FintupButton type="submit" size={constants.BUTTON_SIZE.XLARGE} className="loginButtonContainer fRight" buttonClassName="login-color" textKey="home.init" action={this.doLogin.bind(this)} />
          <div className="clearAll"/>
        </SingleFormContainer>
      </div>
    )
  }
}

export default injectIntl(Home)
