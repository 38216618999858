import React from 'react'
import { browserHistory } from 'react-router'
import SessionController from '../core/SessionMobx'
import { eventPool } from '../utils/eventHelper'
import _ from 'lodash'
import Async from 'react-code-splitting'
import Home from '../components/home/Home'
import { getBooleanConfig, getFlowMeterConfig } from '../config/config'
import HistoryAdviceClientView from '../components/history/HistoryAdviceClientView'

const PosicionGlobalView = props => <Async load={import('../components/globalPosition/PosicionGlobal')} componentProps={props} />
const BackofficeOperationsView = props => <Async load={import('../components/backofficeOperations/BackofficeOperationsView')} componentProps={props} />
const UserSelectionView = props => <Async load={import('../components/userSelection/UserSelectionView')} componentProps={props} />
const ContributionView = props => <Async load={import('../components/contribution/ContributionView')} componentProps={props} />
const CreateOperationView = props => <Async load={import('../components/contribution/CreateOperationView')} componentProps={props} />
const ContributionDetailView = props => <Async load={import('../components/contribution/ContributionDetailView')} componentProps={props} />
const DisplayView = props => <Async load={import('../components/display/DisplayView')} componentProps={props} />
const ContractsView = props => <Async load={import('../components/contracts/ContractsView')} componentProps={props} />
const OrphansContractsView = props => <Async load={import('../components/contracts/OrphansContractsView')} componentProps={props} />
const ContractDetailView = props => <Async load={import('../components/contracts/ContractDetailView')} componentProps={props} />
const AcademyView = props => <Async load={import('../components/academy/AcademyView')} componentProps={props} />
const AcademyCategoriesView = props => <Async load={import('../components/academy/AcademyCategoriesView')} componentProps={props} />
const AcademyOptionsView = props => <Async load={import('../components/academy/AcademyOptionsView')} componentProps={props} />
const ConfigurationView = props => <Async load={import('../components/configuration/ConfigurationView')} componentProps={props} />
const CacheView = props => <Async load={import('../components/cache/CacheView')} componentProps={props} />
const ActivationMailView = props => <Async load={import('../components/mail/ActivationMailView')} componentProps={props} />
const NewUserView = props => <Async load={import('../components/user/NewUserView')} componentProps={props} />
const UserDetailsView = props => <Async load={import('../components/user/UserDetailsView')} componentProps={props} />
const NoEmailUsersView = props => <Async load={import('../components/user/NoEmailUsersView')} componentProps={props} />
const MainReportView = props => <Async load={import('../components/report/MainReportView')} componentProps={props} />
const UserReportView = props => <Async load={import('../components/report/UserReportView')} componentProps={props} />
const SignListView = props => <Async load={import('../components/sign/SignListView')} componentProps={props} />
const SignDetailView = props => <Async load={import('../components/sign/SignDetailView')} componentProps={props} />
const AdviserView = props => <Async load={import('../components/adviser/AdviserView')} componentProps={props} />
const AdviserDetailView = props => <Async load={import('../components/adviser/AdviserDetailView')} componentProps={props} />
const AdviserQuestionsView = props => <Async load={import('../components/adviser/AdviserQuestionsView')} componentProps={props} />
const RegisterMailView = props => <Async load={import('../components/registerMail/RegisterMailView')} componentProps={props} />
const SessionsView = props => <Async load={import('../components/sessions/SessionsView')} componentProps={props} />
const AcknowledgmentView = props => <Async load={import('../components/acknowledgment/AcknowledgmentView')} componentProps={props} />
const CorsView = props => <Async load={import('../components/cors/CorsView')} componentProps={props} />
const PendingOperationsView = props => <Async load={import('../components/pendingOperations/PendingOperationsView')} componentProps={props} />
const InvitationsView = props => <Async load={import('../components/invitation/InvitationsView')} componentProps={props} />
const InvitationDetailView = props => <Async load={import('../components/invitation/InvitationDetailView')} componentProps={props} />
const LeadsView = props => <Async load={import('../components/leads/LeadsView')} componentProps={props} />
const MovementsView = props => <Async load={import('../components/movements/MovementsView')} componentProps={props} />
const InvitationUserListView = props => <Async load={import('../components/invitation/InvitationUserListView')} componentProps={props} />
const UserCardView = props => <Async load={import('../components/user/UserCardView')} componentProps={props} />
const IddView = props => <Async load={import('../components/idd/IddView')} componentProps={props} />
const IddListView = props => <Async load={import('../components/idd/IddListView')} componentProps={props} />
const IddDetailView = props => <Async load={import('../components/idd/IddDetailView')} componentProps={props} />
const ProcessedFilesView = props => <Async load={import('../components/processedFiles/ProcessedFilesView')} componentProps={props} />
const ConsolidateSummaryView = props => <Async load={import('../components/consolidateSummary/ConsolidateSummaryView')} componentProps={props} />
const ErrorLoginView = props => <Async load={import('../components/errorLogin/ErrorLoginView')} componentProps={props} />
const NotificationsView = props => <Async load={import('../components/notifications/NotificationsView')} componentProps={props} />
const NotificationsBackView = props => <Async load={import('../components/notifications/NotificationsBackView')} componentProps={props} />
const NotificationDetailView = props => <Async load={import('../components/notifications/NotificationDetailView')} componentProps={props} />
const NotificationCreateView = props => <Async load={import('../components/notifications/NotificationCreateView')} componentProps={props} />
const NotificationsTypeView = props => <Async load={import('../components/notifications/NotificationsTypeView')} componentProps={props} />
const NotificationTypeCreateView = props => <Async load={import('../components/notifications/NotificationTypeCreateView')} componentProps={props} />
const ListUsersByProductView = props => <Async load={import('../components/user/ListUsersByProductView')} componentProps={props} />

let groupRoutes = [
  {
    id: 1,
    name: "Usuarios"
  },
  {
    id: 2,
    name: "Listados"
  },
  {
    id: 3,
    name: "Configuración"
  }
]

let routes = [
  {
    view: PosicionGlobalView,
    path: '/posicion-global'
  },
  {
    view: UserDetailsView,
    path: '/usuarios/detalle/:dni'
  },

  {
    view: BackofficeOperationsView,
    path: '/backoffice'
  },
  {
    view: UserSelectionView,
    path: '/seleccion-usuarios',
    textKey: 'operations.selection',
    mainOperation: true,
    order: 10,
    idGroup: 1
  },
  {
    view: ContractsView,
    path: '/contratos',
    textKey: 'operations.contracts',
    mainOperation: true,
    order: 20,
    idGroup: 1
  },
  {
    view: ContributionView,
    path: '/operaciones/:selectedDni',
    route: '/operaciones/UserController.user.dni',
    textKey: 'operations.operations',
    mainOperation: true,
    order: 30,
    idGroup: 1
  },
  {
    view: UserDetailsView,
    path: '/usuarios/modificar',
    textKey: 'operations.userModify',
    mainOperation: true,
    order: 40,
    idGroup: 1
  },
  {
    view: CreateOperationView,
    path: '/operaciones/crear/:selectedDni/:productId/:policyNumber'
  },
  {
    view: ContributionDetailView,
    path: '/operacion/:selectedDni/:operationId'
  },
  {
    view: HistoryAdviceClientView,
    path: '/historial-cliente',
    route: '/historial-cliente',
    textKey: 'operations.history',
    mainOperation: true,
    order: 45,
    idGroup: 1
  },
  {
    view: SignListView,
    path: '/firmas/:selectedDni',
    route: '/firmas/UserController.user.dni',
    textKey: 'operations.sign',
    mainOperation: true,
    order: 50,
    idGroup: 1
  },
  {
    view: DisplayView,
    path: '/display',
    textKey: 'operations.display',
    mainOperation: true,
    order: 20,
    idGroup: 3
  },
  {
    view: OrphansContractsView,
    path: '/contratos/huerfanos',
    textKey: 'operations.contracts.orphans',
    mainOperation: true,
    order: 300,
    idGroup: 2
  },
  {
    view: NoEmailUsersView,
    path: '/usuarios/no-email',
    textKey: 'operations.noEmailUsers',
    mainOperation: true,
    order: 320,
    idGroup: 2
  },
  {
    view: ListUsersByProductView,
    path: '/usuarios/por-producto',
    textKey: 'operations.listUserProduct',
    mainOperation: true,
    order: 320,
    idGroup: 2
  },
  {
    view: SignDetailView,
    path: '/firmas/:selectedDni/:signId'
  },
  {
    view: ContractDetailView,
    path: '/contrato/:userDni/:productId/:policyNumber'
  },
  {
    view: AcademyView,
    path: '/academia',
    textKey: 'operations.academy',
    mainOperation: true,
    order: 70,
    idGroup: 3
  },
  {
    view: AcademyCategoriesView,
    path: '/academia/categorias'
  },
  {
    view: AcademyOptionsView,
    path: '/academia/opciones'
  },
  {
    view: NewUserView,
    path: '/usuarios/nuevo',
    textKey: 'operations.newUser',
    mainOperation: true,
    order: 320,
    idGroup: 1
  },
  {
    view: Home,
    path: '/'
  },
  {
    view: ConfigurationView,
    path: '/configuracion',
    textKey: 'operations.configuration',
    mainOperation: true,
    order: 10,
    idGroup: 3
  },
  {
    view: ActivationMailView,
    path: '/mail/activation',
    textKey: 'operations.activeContract',
    mainOperation: true,
    order: 100,
    idGroup: 3
  },
  {
    view: CacheView,
    path: '/cache',
    textKey: 'operations.cache',
    mainOperation: true,
    order: 30,
    idGroup: 3
  },
  {
    view: MainReportView,
    path: '/reporte/principal',
    textKey: 'operations.report.main',
    mainOperation: true,
    order: 10,
    idGroup: 2
  },
  {
    view: UserReportView,
    path: '/reporte/usuarios/:pageNumber'
  },
  {
    view: AdviserView,
    path: '/gestores',
    textKey: 'operations.adviser',
    mainOperation: true,
    order: 40,
    idGroup: 3
  },
  {
    view: AdviserQuestionsView,
    path: '/gestores/dudas/listado/:pageNumber',
    order: 140
  },
  {
    view: AdviserDetailView,
    path: '/gestores/:id'
  },
  {
    view: RegisterMailView,
    path: '/registro-email',
    textKey: 'operations.mailRegister',
    mainOperation: true,
    order: 150,
    idGroup: 2
  },
  {
    view: SessionsView,
    path: '/sesiones',
    textKey: 'operations.sessions',
    mainOperation: true,
    order: 170,
    idGroup: 2
  },
  {
    view: AcknowledgmentView,
    path: '/acuse-recibo',
    textKey: 'operations.acknowledgment',
    mainOperation: true,
    order: 170,
    idGroup: 2
  },
  {
    view: CorsView,
    path: '/cors',
    textKey: 'operations.cors',
    mainOperation: true,
    order: 190,
    idGroup: 3
  },
  {
    view: PendingOperationsView,
    path: '/operaciones-pendientes',
    textKey: 'operations.pendings',
    mainOperation: true,
    order: 30,
    idGroup: 2
  },
  {
    view: InvitationsView,
    path: '/invitaciones/:type/:pageNumber',
    route: '/invitaciones/todas/1',
    textKey: 'operations.invitations',
    mainOperation: true,
    order: 210,
    idGroup: 2
  },
  {
    view: InvitationUserListView,
    path: '/invitaciones-usuarios/:pageNumber'
  },
  {
    view: InvitationDetailView,
    path: '/detalle-invitacion/:invitationId'
  },
  {
    view: LeadsView,
    path: '/leads/:pageNumber',
    route: '/leads/1',
    textKey: 'operations.leads',
    mainOperation: true,
    order: 220,
    idGroup: 2
  },
  {
    view: MovementsView,
    path: '/contratos/movimientos/:userDni/:productId/:policyNumber/:pageNumber'
  },
  {
    view: ConsolidateSummaryView,
    path: '/consolidaciones/:pageNumber/:selectedDni',
    route: '/consolidaciones/1/UserController.user.dni',
    textKey: 'operations.consolidateSummary',
    mainOperation: true,
    idGroup: 2,
    order: 260,
    disableOperation: () => {
      return !getFlowMeterConfig('summaryGraphicFlowMeter_BO')
    }
  },
  {
    view: ErrorLoginView,
    path: '/errorLogin/:pageNumber',
    route: '/errorLogin/1',
    textKey: 'operations.errorLogin',
    mainOperation: true,
    order: 270,
    idGroup: 2
  },
  {
    view: UserCardView,
    path: '/ficha/:selectedDni',
    route: '/ficha/UserController.user.dni',
    textKey: 'operations.card',
    mainOperation: true,
    order: 230,
    idGroup: 1
  },
  {
    view: IddView,
    path: '/idd/reproducir/:selectedDni/:selectedIdd'
  },
  {
    view: IddView,
    path: '/idd/:selectedDni/:selectedOperation'
  },
  {
    view: IddView,
    path: '/idd/:selectedDni'
  },
  {
    view: IddDetailView,
    path: '/idd/detalle/:selectedDni/:selectedIdd'
  },
  {
    view: IddListView,
    path: '/idd-list/:pageNumber/:selectedDni',
    route: '/idd-list/1/UserController.user.dni',
    textKey: 'operations.IDD',
    mainOperation: true,
    order: 70,
    idGroup: 1,
    disableOperation: () => !getBooleanConfig('enableIdd')
  },
  {
    view: IddListView,
    path: '/idd-list/:selectedOperation/:pageNumber/:selectedDni'
  },
  {
    view: ProcessedFilesView,
    path: '/ficheros-procesados/:pageNumber',
    route: '/ficheros-procesados/1',
    textKey: 'operations.processedFiles',
    mainOperation: true,
    order: 250,
    idGroup: 2
  },
  /*{
    view: NotificationsView,
    path: '/notificaciones',
    route: '/notificaciones',
    textKey: 'operations.notifications',
    mainOperation: true,
    order: 20,
    idGroup: 2
  },*/
  {
    view: NotificationsBackView,
    path: '/notificaciones',
    route: '/notificaciones',
    textKey: 'operations.notificationsBack',
    mainOperation: true,
    order: 1,
    idGroup: 2
  },
  {
    view: NotificationCreateView,
    path: '/notificaciones/crear'
  },
  {
    view: NotificationDetailView,
    path: '/detalle-notificacion/:notificationId'
  },
  {
    view: NotificationsTypeView,
    path: '/notificaciones/tipos'
  },
  {
    view: NotificationTypeCreateView,
    path: '/notificaciones/tipos/crear'
  },
  {
    view: Home,
    path: '*'
  }
]

let defaultConfig = {
  fullScreen: false,
  showFooter: true,
  isWidget: false
}

let routesConfig = [
  {
    route: '/suitability',
    fullScreen: true
  },
  {
    route: '/forms/',
    fullScreen: true
  },
  {
    route: '/invest',
    fullScreen: true
  },
  {
    route: '/simulator-widget',
    fullScreen: true,
    showFooter: false
  },
  {
    route: '/simulator',
    fullScreen: true
  },
  {
    route: '/single-simulador',
    fullScreen: true,
    showFooter: false
  },
  {
    route: '/simulador',
    fullScreen: true,
    showFooter: false,
    isWidget: true
  },
  {
    route: '/sso/',
    fullScreen: true
  },
  {
    route: '/emailValidate/',
    fullScreen: true
  }
]

class ConfigRoutesController {
  constructor() {
    this.routes = routes
    this.groupRoutes = groupRoutes
    browserHistory.listen(location => {
      this.checkRoute(location)
      this.stopStreams()
    })
  }

  stopStreams() {
    for (let index in eventPool) {
      let eventItem = eventPool[index]
      eventItem.close()
    }
  }

  checkRoute(location) {
    if (!location) {
      location = browserHistory.getCurrentLocation()
    }
    var pathname = location.pathname
    let configOfRoute = _.extend(_.cloneDeep(defaultConfig), _.find(routesConfig, function (routeObj) {
      return pathname.indexOf(routeObj.route) === 0
    }))
    this.checkShowFooter(configOfRoute)
    this.checkFullScreen(configOfRoute)
    this.checkWidget(configOfRoute)
  }

  checkWidget(config) {
    if (SessionController.isWidget !== config.isWidget) {
      SessionController.isWidget = config.isWidget
    }
  }

  checkFullScreen(config) {
    if (SessionController.isFullScreen !== config.fullScreen) {
      SessionController.isFullScreen = config.fullScreen
    }
  }

  checkShowFooter(config) {
    if (SessionController.showFooter !== config.showFooter) {
      SessionController.showFooter = config.showFooter
    }
  }
}

var ConfigRoutes = new ConfigRoutesController()
export default ConfigRoutes
