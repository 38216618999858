import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import constants from '../../../utils/constants'
import FontAwesome from 'react-fontawesome'
import Spinner from 'react-spinkit'
import './FintupButton.scss'

class FintupButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
  }

  doClick (ev) {
    if (!this.props.isLoading && this.props.action) {
      this.props.action(ev)
    }
  }

  get sizeClass () {
    switch (this.props.size) {
      case constants.BUTTON_SIZE.LARGE:
        return 'btn-l'
      case constants.BUTTON_SIZE.DOUBLE_LARGE:
        return 'btn-2l'
      case constants.BUTTON_SIZE.XLARGE:
        return 'btn-xl'
      case constants.BUTTON_SIZE.XXLARGE:
        return 'btn-xxl'
      case constants.BUTTON_SIZE.XXXLARGE:
        return 'btn-xxxl'
      case constants.BUTTON_SIZE.FULL:
        return 'btn-full'
      default:
        return 'btn-m'
    }
  }

  get loadingButtonClass () {
    return this.props.isLoading ? 'btn-loading' : ''
  }

  get buttonType () {
    return this.props.type || 'button'
  }

  get disabledState () {
    return this.props.disabled ? 'disabled' : ''
  }

  get buttonContent () {
    let content
    if (this.props.icon) {
      content = (<div><FontAwesome name={this.props.icon} className="marginRight10"/><FormattedMessage id={this.props.textKey} /></div>)
    } else {
      content = <FormattedMessage id={this.props.textKey} />
    }
    return content
  }

  render () {
    let containerClass = classNames('fintupButton', this.props.className, this.loadingButtonClass)
    let buttonClasses = classNames('btn btn-fintup', this.props.buttonClassName, this.sizeClass)
    let buttonContent = this.props.isLoading ? <Spinner name='circle' fadeIn='none' /> : this.buttonContent
    return (
      <div className={containerClass}>
        <button type={this.buttonType} className={buttonClasses} disabled={this.disabledState} onClick={this.doClick.bind(this)}>
          {buttonContent}
        </button>
      </div>
    )
  }
}

export default injectIntl(FintupButton)
