
let constants = {
  alertType: {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  },
  BUTTON_SIZE: {
    LARGE: 'LARGE',
    DOUBLE_LARGE: 'DOUBLE_LARGE',
    XLARGE: 'XLARGE',
    XXLARGE: 'XXLARGE',
    XXXLARGE: 'XXXLARGE',
    FULL: 'FULL'
  },
  CHART_COLORS: {
    COLOR1: '#5ab3ca',
    COLOR2: '#ffd36d',
    COLOR3: '#687fd2',
    COLOR4: '#ffb66d'
  },
  CONTRACT: {
    ACCOUNT: {
      HEIRS: {
        HEIRS: 'H',
        DESIGNATION: 'D'
      },
      BENEFICIARIES: {
        MAX_BENEFICIARIES: 5
      }
    }
  },
  COOKIE: {
    LANGUAGE: 'fintupLanguage',
    TERMS: 'fintupAppCookies',
    TERMS_EXPIRED_DAYS: 60
  },
  GLOBAL_POSITION: {
    RENDER_TYPE: {
      DATA: 'DATA',
      EXTENDED: 'EXTENDED_DATA'
    }
  },
  MANAGERIMAGE: {
    DIRECTION: {
      BOTTOM: 'arrowBottom',
      RIGHT: 'arrowRight'
    }
  },
  MAP: {
    THEME: {
      DARK: 'grayScaleDarkMap',
      LIGHT: 'garyScaleLightMap'
    }
  },
  CHARS: {
    SLASH: '/',
    EMPTY: '',
    BLANK: ' '
  },
  FILE_STATE: {
    UPLOADED: 'UPLOAD_OK',
    PENDING: 'PENDING'
  },
  PRODUCT_STATE: {
    DOCUMENT_PENDING: 'PENDIENTE_DOCUMENTACION'
  },
  ACCORDION: {
    SIDE_LEFT: 1,
    SIDE_RIGHT: 2
  },
  EMAIL_VALIDATE: {
    TOKEN_SPLIT: '::'
  },
  RESULT: {
    OK: 'OK'
  },
  SLIDERFORMATTER: {
    AMOUNT: 1,
    YEAR: 2
  },
  FORMS: {
    exigencias: 's8ldr60pop7kv4knvdf97tkmn0',
    adecuacion: 'b54eh5797gg58r9ke8sg9fuooh',
    idoneidad: 'ai7k83550g2mf191h3jc3dh7dm'
  },
  REGISTER: {
    ORIGIN: {
      BACKOFFICE: 'FINTUPBACKOFFICE'
    }
  },
  NEW_USER: {
    FORM_MODE: {
      MODIFY_EMAIL: 1,
      UPDATE_USER: 2
    }
  },
  DEFAULT_INVEST_PRODUCT: 20812,
  NOTIFICATIONS: {
    READ: 'READ',
    UNREAD: 'UNREAD',
    TYPES: {
      BASIC_BACK: 'BASIC_BACK',
      OPERATIVE_BACK: 'OPERATIVE_BACK',
      OPERATIVE: 'OPERATIVE',
      GENERAL: 'GENERAL',
      BASIC: 'BASIC'
    }
  },
}

export default constants
