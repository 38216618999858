import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import esLocale from 'react-intl/locale-data/es'
import Fintup from '../components/Fintup'
import Cookies from 'js-cookie'
import constants from '../utils/constants'
import {doGetCdnLiterals} from '../utils/fintup-api'
import LoadingApp from '../components/common/LoadingApp'
import FintupComponent from '../components/FintupComponent'
import ReactGA from 'react-ga'
import config from '../config/config'
import hotconfig from '../config/hotConfig'
import RSVP from 'rsvp'
import 'moment/locale/es'
import moment from 'moment'

addLocaleData([...esLocale])

class FintupInt extends FintupComponent {
  constructor (props) {
    let initLanguage = 'es'
    super(props)
    moment.locale(initLanguage)
    this.state = {
      loadingApp: true,
      literals: {},
      language: initLanguage
    }
  }

  componentWillMount () {
    Cookies.set(constants.COOKIE.LANGUAGE, this.state.language)
  }

  loadAnalytics () {
    if (config.googleAnalyticsEnabled) {
      let googleAnalyticsOptions = {
        gaOptions: config.googleAnalyticsOptions || {}
      }
      ReactGA.initialize(config.googleAnalytics, googleAnalyticsOptions)
    }
    return RSVP.resolve()
  }

  componentDidMount () {
    hotconfig.init()
      .then(() => this.loadAnalytics(this.state.language))
      .then(() => doGetCdnLiterals(this.state.language))
      .then((response) => this.setStatePromise({loadingApp: false, literals: response.data}))
      .catch((e) => {
        console.log(e)
      })
  }

  get renderedContent () {
    let content
    if (this.state.loadingApp) {
      content = <LoadingApp/>
    } else {
      content = (<IntlProvider locale={this.state.language} messages={this.state.literals}>
        <Fintup />
      </IntlProvider>)
    }
    return content
  }

  render () {
    return this.renderedContent
  }
}

export default FintupInt
