import axios from 'axios'
import SessionController from '../core/SessionMobx'
import config from '../config/config'
import RSVP from 'rsvp'
import _ from 'lodash'
import transverser from '../utils/transverser'
import navUtils from '../core/navUtils'
import fileDownload from 'js-file-download'

export {
  contributionList,
  loginBackOfficePost,
  getContributionDetail,
  doGetCdnLiterals,
  doGetHotConfig,
  userList,
  getOperationStates,
  doLoginSocial,
  doUploadDoc,
  sendContributionNotification,
  updateContributionDocument,
  updateContributionState,
  adminGetDisplay,
  adminUpdateDisplay,
  adminContractList,
  adminPortfolioList,
  portfolioAdviceList,
  adminNotificationList,
  adminNotificationBackList,
  adminUnreadNotificationBackList,
  readAdminNotification,
  adminNotificationDetail,
  adminNotificationCreate,
  adminDeleteNotification,
  adminNotificationTypeList,
  adminBasicNotificationTypeList,
  adminDeleteNotificationType,
  adminNotificationTypeCreate,
  doGetMaritalTypeList,
  adviceObjectivesList,
  adviceCategoriesList,
  adminHistoryAdviceList,
  adminHistoryAdviceCreate,
  adminHistoryAdviceSimulate,
  adminHistoryAdviceObjectiveSimulate,
  adminHistoryAdviceObjectiveCreate,
  historyAdviceObjectiveDelete,
  adminHistoryAdviceHistoryCreate,
  adminPortfolioByProductList,
  adminProductList,
  changeContractPolicyNumber,
  changeContractPortfolio,
  changeContractProduct,
  adminGetAcademyCategories,
  adminPutAcademyCategory,
  adminPostAcademyCategory,
  adminDeleteAcademyCategory,
  adminGetAcademyCategoryOptions,
  adminPutAcademyOption,
  adminDeleteAcademyOption,
  adminPostAcademyOption,
  adminClearConfigCache,
  clearAppCaches,
  adminPostConfiguration,
  adminGetConfigurationList,
  adminDeleteConfiguration,
  adminSendActivationMail,
  adminSendWelcomeMail,
  adminRegisterUser,
  adminMainReportGet,
  sendOperationToSignGateway,
  adminSignList,
  adminProcessAllSigns,
  adminSignDetail,
  adminProcessSign,
  adminProcessSignUncheck,
  adminCancelSign,
  adminGetContributionBySign,
  adminOrphansContractsList,
  adminNoEmailUsers,
  adminUserDetails,
  adminUserDetailsByEmail,
  adminUserDetailsById,
  adminUpdateEmail,
  adminUpdateAdviser,
  adminDeleteContract,
  adminAviserList,
  adminAdviserDetail,
  adminAdviserModify,
  adminAdviserDelete,
  adminAdviserCreate,
  adminRegisterMailList,
  adminZipSignFiles,
  adminUpdateUser,
  adminMainReportHistory,
  adminSessionList,
  adminSessionListFind,
  sendPrecontractualData,
  adminAcknowledgmentDetail,
  adminAcknowledgmentRemove,
  adminAcknowledgmentList,
  adminGetCors,
  adminPostCors,
  adminDeleteCors,
  adminPendingOperations,
  adminInvitationList,
  adminInvitationDetail,
  adminInvitationVoucherUpdate,
  adminInvitationConfirmVoucher,
  adminRemoveOperation,
  adminLeadList,
  adminDeleteUser,
  adminMovementsList,
  adminMovementsUpdate,
  adminInvitedEmaiList,
  contractUserDocuments,
  existContractUserData,
  updatePolicyNumber,
  adminRemoveOperationAmount,
  adminUpdateOperationAmount,
  adminUpdateInformativeNote,
  postStartIdd,
  patchSaveIdd,
  getIddProducts,
  getLastIdd,
  iddResult,
  iddDocument,
  getContractDetail,
  adminIddList,
  adminRemoveIdd,
  adminGetIdd,
  downloadIddFile,
  doGetHotConfigBackend,
  adminProcessedFileList,
  adminMigrateContracts,
  adminAdviserQuestionList,
  adminAdviserQuestionExportCsv,
  adminConsolidateSummayList,
  adminConsolidateSummayChangeState,
  adminUserReport,
  adminUserReportExport,
  adminUserReportExportWindow,
  adminUsersByProductAndPortfolio,
  getUserData,
  getContractsData,
  adminLoginErrorList,
  adminBackofficeOperationList,
  adminBackofficeCreateOperation
}

function generalRequest(axiosPromise, rsvpResolve, rspvReject) {
  axiosPromise.then(response => {
    let responseCode = transverser.get('data.result.code')(response) || transverser.get('data.code')(response) || transverser.get('data.resultDto.code')(response)
    if (response.status >= 200 && response.status < 300 && responseCode !== 'KO') {
      rsvpResolve(response)
    } else {
      rspvReject(response.data.result || response.data)
    }
  }).catch(error => {
    if (error.response && error.response.status === 401 && transverser.get('response.data.code', '')(error) === 'SESSIONEXPIRED') {
      SessionController.sessionExpired()
    } else {
      let message = transverser.get('response.data.message', 'Ha ocurrido un error inesperado')(error)
      if (message === 'null') {
        message = 'Ha ocurrido un error inesperado'
      }
      let result = {
        code: transverser.get('response.status', 500)(error),
        message: message
      }
      rspvReject(result)
    }
  })
}

function _isDeniedSecureRequest(isSecureRequest) {
  let isEmulated = SessionController.isEmulatedSession
  return isSecureRequest && isEmulated
}

function _getSecureRequestPromise() {
  let result = {
    code: 500,
    message: 'Sesion Emulada, operación no permitida'
  }
  return RSVP.reject(result)
}

function doPost(url, data, isSecureRequest = false, uploadRequest = false) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.post(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doDelete(url, isSecureRequest = false, data = {}) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let urlNotCache = url.indexOf('?') === -1
        ? `${url}?${new Date().getTime()}`
        : `${url}&${new Date().getTime()}`
      let deleteData = _.extend({}, getHeaders(), { data: data })
      let axiosPromise = axios.delete(urlNotCache, deleteData)
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function changeContractPolicyNumber(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/change-policy-number`
  return doPost(url, data)
}

function changeContractProduct(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/change-product`
  return doPost(url, data)
}

function changeContractPortfolio(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/change-portfolio`
  return doPost(url, data)
}

function contributionList(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/${data.userId}`
  return doGet(url)
}


function adminGetContributionBySign(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/by-sign/${data.signId}`
  return doGet(url)
}

function adminSignList(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/list/${data.userId}`
  return doGet(url)
}

function adminZipSignFiles(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/${data.signId}/get-gateway-documents`
  return doGet(url)
}

function adminProcessAllSigns(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/process-all`
  return doGet(url)
}

function adminCancelSign(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/cancel?signatureId=${data.id}`
  return doPost(url)
}

function adminProcessSign(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/process/${data.id}`
  return doGet(url)
}

function adminProcessSignUncheck(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/process/${data.signId}/uncheck`
  return doGet(url)
}

function adminSignDetail(data) {
  const url = `${config.apiBaseUrl}/api/sign/admin/${data.id}`
  return doGet(url)
}

function adminContractList(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/${data.userId}`
  return doGet(url)
}

function portfolioAdviceList() {
  const url = `${config.apiBaseUrl}/api/portfolio/list-advice`
  return doGet(url)
}


function adminPortfolioList() {
  const url = `${config.apiBaseUrl}/api/portfolio/admin/list`
  return doGet(url)
}

function adminPortfolioByProductList(productId) {
  const url = `${config.apiBaseUrl}/api/portfolio/admin/${productId}/list`
  return doGet(url)
}

function adminProductList() {
  const url = `${config.apiBaseUrl}/api/product/admin/list`
  return doGet(url)
}

function adminNotificationList() {
  const url = `${config.apiBaseUrl}/api/notification/admin/list`
  return doGet(url)
}

function adminNotificationBackList() {
  const url = `${config.apiBaseUrl}/api/notification/admin/list-back`
  return doGet(url)
}

function adminUnreadNotificationBackList() {
  const url = `${config.apiBaseUrl}/api/notification/admin/unread`
  return doGet(url)
}

function adminDeleteNotification(data) {
  const url = `${config.apiBaseUrl}/api/notification/admin/${data.id}`
  return doDelete(url)
}

function adminNotificationDetail(data) {
  const url = `${config.apiBaseUrl}/api/notification/admin/${data.id}`
  return doGet(url)
}

function adminNotificationCreate(data) {
  const url = `${config.apiBaseUrl}/api/notification/admin`
  return doPost(url, data)
}

function adminDeleteNotificationType(data) {
  const url = `${config.apiBaseUrl}/api/notification-type/admin/${data.id}`
  return doDelete(url)
}

function adminNotificationTypeList() {
  const url = `${config.apiBaseUrl}/api/notification-type/admin/list`
  return doGet(url)
}

function adminBasicNotificationTypeList() {
  const url = `${config.apiBaseUrl}/api/notification-type/admin/list/basic`
  return doGet(url)
}

function readAdminNotification(data) {
  const url = `${config.apiBaseUrl}/api/notification/admin/${data.notificationId}/read`
  return doPost(url, data)
}

function adminNotificationTypeCreate(data) {
  const url = `${config.apiBaseUrl}/api/notification-type/admin`
  return doPost(url, data)
}

function adviceObjectivesList() {
  const url = `${config.apiBaseUrl}/api/advice-objectives/list`
  return doGet(url)
}

function adviceCategoriesList() {
  const url = `${config.apiBaseUrl}/api/advice-categories/list`
  return doGet(url)
}

function adminHistoryAdviceList(data) {
  const url = `${config.apiBaseUrl}/api/user-advice/admin/list/${data.userId}`
  return doGet(url)
}

function adminHistoryAdviceCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice/admin`
  return doPost(url, data)
}

function adminHistoryAdviceSimulate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice/simulate`
  return doPost(url, data)
}


function adminHistoryAdviceObjectiveCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/admin`
  return doPost(url, data)
}

function historyAdviceObjectiveDelete(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/${data}`
  return doDelete(url)
}

function adminHistoryAdviceObjectiveSimulate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-objective/simulate`
  return doPost(url, data)
}

function doGetMaritalTypeList() {
  console.log("maritalType get")
  const url = `${config.cdnBaseUrl}/json/maritalTypeList.json`
  return doGet(url)
}

function adminHistoryAdviceHistoryCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-advice-history/admin`
  return doPost(url, data)
}

function updatePolicyNumber(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/updatePolicyNumber`
  return doPatch(url, data)
}

function adminRemoveOperationAmount(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/remove-amount`
  return doPatch(url, data)
}

function adminUpdateOperationAmount(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/update-amount`
  return doPatch(url, data)
}

function getContributionDetail(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/${data.userId}/${data.id}`
  return doGet(url)
}

function getContractDetail(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/${data.userId}/${data.contractId}`
  return doGet(url)
}

function adminGetAcademyCategories() {
  const url = `${config.apiBaseUrl}/api/academy/admin/category/list`
  return doGet(url)
}

function adminGetAcademyCategoryOptions(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/option/${data.id}/list`
  return doGet(url)
}

function adminRegisterMailList(data) {
  const url = `${config.apiBaseUrl}/api/register-mail/admin/list${data.params}`
  return doGet(url)
}

function adminSessionList(data) {
  const url = `${config.apiBaseUrl}/api/session/admin/list${data.params}`
  return doGet(url)
}

function adminSessionListFind(data) {
  const url = `${config.apiBaseUrl}/api/session/admin/find`
  return doPost(url, data)
}

function adminAcknowledgmentList(data) {
  const url = `${config.apiBaseUrl}/api/acknowledgment/admin/list${data.params}`
  return doGet(url)
}

function adminInvitationList(data) {
  const url = `${config.apiBaseUrl}/api/invitation/admin/${data.listType}/list${data.params}`
  return doGet(url)
}

function adminInvitedEmaiList(data) {
  const url = `${config.apiBaseUrl}/api/invitation/admin/invited-email-list${data.params}`
  return doGet(url)
}

function adminInvitationDetail(data) {
  const url = `${config.apiBaseUrl}/api/invitation/admin/${data.id}`
  return doGet(url)
}

function adminInvitationVoucherUpdate(data) {
  const url = `${config.apiBaseUrl}/api/invitation/admin/update-voucher-code`
  return doPost(url, data)
}

function adminInvitationConfirmVoucher(data) {
  const url = `${config.apiBaseUrl}/api/invitation/admin/${data.invitationId}/confirm-voucher`
  return doPost(url, data)
}

function adminLeadList(data) {
  const url = `${config.apiBaseUrl}/api/leads/admin/list${data.params}`
  return doGet(url)
}

function adminProcessedFileList(data) {
  const url = `${config.apiBaseUrl}/api/processed-files/admin/list${data.params}`
  return doGet(url)
}

function adminAdviserQuestionList(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin/register/list${data.params}`
  return doGet(url)
}

function adminAdviserQuestionExportCsv(data) {
  const url = `${config.apiBaseUrl}/api/file/adviser-questions/csv?initDate=${data.initDate}&endDate=${data.endDate}`
  let sessionToken = SessionController.token
  navUtils.openPrivateWindow(url, sessionToken)
  return RSVP.resolve()
}

function adminIddList(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/list/${data.userId}${data.params}`
  return doGet(url)
}

function adminMovementsList(data) {
  const url = `${config.apiBaseUrl}/api/global-position/admin/movements/${data.userDni}/${data.policyNumber}${data.params}`
  return doGet(url)
}

function adminConsolidateSummayList(data) {
  const url = `${config.apiBaseUrl}/api/consolidate-summary/admin/list/${data.userDni}${data.params}`
  return doGet(url)
}

function adminLoginErrorList(data) {
  const url = `${config.apiBaseUrl}/api/login-error/admin/list${data.params}`
  return doGet(url)
}

function adminConsolidateSummayChangeState(data) {
  const url = `${config.apiBaseUrl}/api/consolidate-summary/admin/list/change-state`
  return doPost(url, data)
}

function adminMovementsUpdate(data) {
  const url = `${config.apiBaseUrl}/api/global-position/admin/movements`
  return doPost(url, data)
}

function adminAviserList() {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin/list`
  return doGet(url)
}

function adminAdviserDetail(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin/${data.id}`
  return doGet(url)
}

function adminAdviserDelete(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin/${data.id}`
  return doDelete(url)
}

function adminAdviserModify(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin`
  return doPost(url, data)
}

function adminAdviserCreate(data) {
  const url = `${config.apiBaseUrl}/api/user-adviser/admin`
  return doPut(url, data)
}

function adminPutAcademyOption(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/option`
  return doPut(url, data)
}

function adminPutAcademyCategory(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/category`
  return doPut(url, data)
}

function adminPostAcademyCategory(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/category`
  return doPost(url, data)
}

function adminDeleteAcademyCategory(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/category/${data.id}`
  return doDelete(url)
}

function adminClearConfigCache() {
  const url = `${config.apiBaseUrl}/api/cache/admin/config`
  return doDelete(url)
}

function clearAppCaches(param) {
  const url = `${config.apiBaseUrl}/api/helpdesk/admin/clearcache/${param}`
  return doGet(url)
}

function adminGetConfigurationList() {
  const url = `${config.apiBaseUrl}/api/config/admin/list`
  return doGet(url)
}


function adminDeleteConfiguration(data) {
  const url = `${config.apiBaseUrl}/api/config/admin/${data.id}`
  return doDelete(url)
}

function adminPostConfiguration(data) {
  const url = `${config.apiBaseUrl}/api/config/admin`
  return doPost(url, data)
}

function adminPostAcademyOption(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/option`
  return doPost(url, data)
}

function adminDeleteAcademyOption(data) {
  const url = `${config.apiBaseUrl}/api/academy/admin/option/${data.id}`
  return doDelete(url)
}

function adminMainReportGet() {
  const url = `${config.apiBaseUrl}/api/report/admin/main`
  return doGet(url)
}

function adminMainReportHistory(data) {
  const url = `${config.apiBaseUrl}/api/report/admin/main/list?reportType=${data.reportType}${data.params}`
  return doGet(url)
}

function adminUserReport(data) {
  const url = `${config.apiBaseUrl}/api/report/admin/users${data.params}`
  return doGet(url)
}

function adminUsersByProductAndPortfolio(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/list-users-product/${data.productId}/${data.portfolioId}`
  return doGet(url)
}


function adminUserReportExportWindow(data) {
  const url = `${config.apiBaseUrl}/api/file/report/users/export`
  let sessionToken = SessionController.token
  navUtils.openPrivateWindow(url, sessionToken)
  return RSVP.resolve()
}

function adminUserReportExport(data) {
  const url = `${config.apiBaseUrl}/api/report/admin/users/export`
  return doGetBinary(url)
    .then(response => {
      fileDownload(response.data, 'export.csv')
      return RSVP.resolve()
    })
}

function adminGetDisplay() {
  const url = `${config.apiBaseUrl}/api/display/admin/`
  return doGet(url)
}

function updateContributionState(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/${data.userId}/${data.id}/state`
  return doPost(url, data)
}

function adminRemoveOperation(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/${data.id}`
  return doDelete(url, data)
}

function adminUpdateDisplay(data) {
  const url = `${config.apiBaseUrl}/api/display/admin/`
  return doPost(url, data)
}

function getOperationStates() {
  const url = `${config.apiBaseUrl}/api/options/operationStates`
  return doGet(url)
}

function getUserData(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/user-data/${data.userDocument}`
  return doGet(url)
}

function getContractsData(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin/contract-data/${data.userId}`
  return doGet(url)
}

function adminSendActivationMail(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/mail/newContract`
  return doPost(url, data)
}

function adminSendWelcomeMail(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/mail/welcomeMail`
  return doPost(url, data)
}

function adminRegisterUser(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/register`
  return doPost(url, data)
}

function doPut(url, data, isSecureRequest = false, uploadRequest = false) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.put(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doPatch(url, data, isSecureRequest) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let axiosPromise = axios.patch(url, data, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doGet(url, isSecureRequest) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let urlNotCache = url.indexOf('?') === -1
        ? `${url}?${new Date().getTime()}`
        : `${url}&${new Date().getTime()}`
      let axiosPromise = axios.get(urlNotCache, getHeaders())
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function doGetBinary(url, isSecureRequest) {
  if (_isDeniedSecureRequest(isSecureRequest)) {
    return _getSecureRequestPromise()
  } else {
    return new RSVP.Promise(function (resolve, reject) {
      let urlNotCache = url.indexOf('?') === -1
        ? `${url}?${new Date().getTime()}`
        : `${url}&${new Date().getTime()}`
      let axiosPromise = axios.get(urlNotCache, getHeaders(false, {}))
      generalRequest(axiosPromise, resolve, reject)
    })
  }
}

function loginBackOfficePost(data) {
  const url = `${config.apiBaseUrl}/api/users/login/backoffice`
  return doPost(url, data)
}

function doLoginSocial(data) {
  const url = `${config.apiBaseUrl}/api/users/login/social/backoffice`
  return doPost(url, data)
}

function sendContributionNotification(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/notification`
  return doPost(url, data)
}

function updateContributionDocument(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/${data.userId}/${data.id}/document`
  return doPost(url, data)
}

function sendOperationToSignGateway(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/signGateway`
  return doPost(url, data)
}

function sendPrecontractualData(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/send-precontractual`
  return doPost(url, data)
}

function userList(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/user-list`
  return doGet(url)
}

function doGetHotConfig() {
  const url = `${config.cdnBaseUrl}${config.hotConfigUrl}`
  return doGet(url)
}

function doGetCdnLiterals(language) {
  const url = `${config.cdnBaseUrl}/json/i18n/${config.cdnEnviroment}/${language}_backoffice.json`
  return doGet(url)
}

function doUploadDoc(data) {
  const url = `${config.apiBaseUrl}/api/files/admin/${data.userId}/uploadFile`
  return doPost(url, data.formData, false, true)
}

function adminOrphansContractsList() {
  const url = `${config.apiBaseUrl}/api/contracts/admin/orphans-contracts`
  return doGet(url)
}

function adminGetCors() {
  const url = `${config.apiBaseUrl}/api/cors/admin/list`
  return doGet(url)
}

function postStartIdd(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/`
  return doPost(url, data)
}

function adminRemoveIdd(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/${data.userId}/${data.id}`
  return doDelete(url, data)
}

function adminGetIdd(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/${data.userId}/${data.id}`
  return doGet(url)
}

function patchSaveIdd(userId, questionaryId, data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/${userId}/${questionaryId}`
  return doPatch(url, data)
}

function getIddProducts(userId, questionaryId) {
  const url = `${config.apiBaseUrl}/api/idd/admin/${userId}/${questionaryId}/products`
  return doGet(url)
}

function getLastIdd(userId) {
  const url = `${config.apiBaseUrl}/api/idd/admin/${userId}/last`
  return doGet(url)
}

function iddResult(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/result`
  return doPost(url, data)
}

function iddDocument(data) {
  const url = `${config.apiBaseUrl}/api/idd/admin/document-to-operation`
  return doPost(url, data)
}

function adminPostCors(data) {
  const url = `${config.apiBaseUrl}/api/cors/admin/`
  return doPost(url, data)
}

function adminDeleteCors(data) {
  const url = `${config.apiBaseUrl}/api/cors/admin/`
  return doDelete(url, false, data)
}

function adminDeleteUser(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/remove-user`
  return doDelete(url, false, data)
}

function adminNoEmailUsers() {
  const url = `${config.apiBaseUrl}/api/users/admin/no-email-list`
  return doGet(url)
}

function adminUserDetails(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/details/${data.userDocument}`
  return doGet(url)
}

function adminUserDetailsByEmail(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/details-email/${data.userEmail}`
  return doGet(url)
}

function adminUserDetailsById(data) {
  console.log("DATA USER DETAILS: ", data)
  const url = `${config.apiBaseUrl}/api/users/admin/details-id/${data.userId}`
  return doGet(url)
}

function adminBackofficeOperationList() {
  const url = `${config.apiBaseUrl}/api/contribution/admin/operation-type/backoffice-list`
  return doGet(url)
}

function adminBackofficeCreateOperation(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/create`
  return doPost(url, data)
}

function contractUserDocuments(data) {
  const url = `${config.apiBaseUrl}/api/contract-main-data/admin/${data.userDocument}/user-documents`
  return doGet(url)
}

function existContractUserData(data) {
  const url = `${config.apiBaseUrl}/api/contract-main-data/admin/${data.userId}/exist`
  return doGet(url)
}

function adminAcknowledgmentDetail(data) {
  const url = `${config.apiBaseUrl}/api/acknowledgment/admin/${data.userId}/${data.acknowledgmentId}`
  return doGet(url)
}

function adminAcknowledgmentRemove(data) {
  const url = `${config.apiBaseUrl}/api/acknowledgment/admin/${data.userId}/${data.acknowledgmentId}`
  return doDelete(url)
}

function adminPendingOperations(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/pending-operations${data.params}`
  return doGet(url)
}

function adminUpdateInformativeNote(data) {
  const url = `${config.apiBaseUrl}/api/contribution/admin/update-informative-note`
  return doPost(url, data)
}

function adminUpdateEmail(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/update-mail`
  return doPost(url, data)
}

function adminUpdateAdviser(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/update-adviser`
  return doPost(url, data)
}

function adminUpdateUser(data) {
  const url = `${config.apiBaseUrl}/api/users/admin/update-user`
  return doPost(url, data)
}

function adminDeleteContract(data) {
  const url = `${config.apiBaseUrl}/api/contracts/admin`
  return doDelete(url, false, data)
}

function downloadIddFile(data) {
  const url = `${config.apiBaseUrl}/api/file/${data.userId}/${data.id}/idd`
  let sessionToken = SessionController.token
  navUtils.openPrivateWindow(url, sessionToken)
  return RSVP.resolve()
}

function adminMigrateContracts() {
  const url = `${config.apiBaseUrl}/api/contracts/admin/contract-positions`
  return doGet(url)
}

function doGetHotConfigBackend() {
  const url = `${config.apiBaseUrl}/api/config/list/BACKOFFICE`
  return doGet(url)
}

function getHeaders(uploadRequest = false, additionalHeaders) {
  let token = SessionController.token
  let headers = {}
  if (!_.isEmpty(token)) {
    _.extend(headers, { token: token })
  }
  if (uploadRequest) {
    _.extend(headers, { 'Content-Type': 'multipart/form-data' })
  }
  if (additionalHeaders) {
    _.extend(headers, additionalHeaders)
  }
  return {
    headers: headers
  }
}
