let urls = {
  image: {
    logo: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/logo-web-fintup.png',
    logoBlancoTransparente: '/img/logo_blanco_transparente.png'
  },
  external: {
    headerAndFooter: {
      fintup: {
        home: 'http://fintup.com/',
        fintup: 'http://fintup.com/filosofia-de-inversion/',
        rates: 'http://fintup.com/tarifas/',
        blog: 'http://fintup.com/blog/',
        contact: 'http://fintup.com/contacto/',
        faq: 'http://fintup.com/faq/',
        invest: 'http://fintup.com/invierte-ahora/',
        clients: 'http://fintup.com/clientes/'
      },
      legal: {
        notice: 'http://fintup.com/aviso-legal/',
        privacyPolicy: 'http://fintup.com/politica-de-privacidad/',
        cookies: 'http://fintup.com/cookies/'
      },
      social: {
        twitter: 'https://twitter.com/fintup',
        linkedin: 'https://www.linkedin.com/company/fintup',
        instagram: 'https://www.instagram.com/fintup/'
      },
      img: {
        appleStore: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/apple-store.png',
        googlePlay: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/google-play.png',
        logoFooter: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/xlogoweb-footer.png'
      }
    }
  },
  internal: {
    login: '/',
    initUrl: '/backoffice',
    documents: '/posicion-global/documents',
    changePassword: '/change-password',
    reactivate: '/reactivate',
    convenience: '/convenience',
    invest: '/suitability',
    helpdesk: '/helpdesk'
  }
}

export default urls
