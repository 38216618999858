import React, { Component } from 'react'
import { Router, Route, browserHistory } from 'react-router'
import SessionController from '../core/SessionMobx'
import classNames from 'classnames'
import CopyRight from '../components/footer/CopyRight'
import ConfigRoutes from '../core/ConfigRoutes'
import CookiesAdvise from '../components/common/CookiesAdvise'
import Header from './header/Header'
import BackButton from './common/BackButton'
import { observer } from 'mobx-react'
import uniqid from 'uniqid'
import './Fintup.css'

class Fintup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
    ConfigRoutes.checkRoute()
  }

  get copyRight () {
    return SessionController.getShowFooter ? <CopyRight /> : ''
  }

  get cookies () {
    return SessionController.getShowFooter ? <CookiesAdvise /> : ''
  }

  get backButton () {
    return SessionController.token !== ''
      ? <BackButton/>
      : ''
  }

  render () {
    let containerClasses = classNames('fintup container-fluid', SessionController.appContainerClass)
    return (
      <div className={containerClasses}>
        <Header />
        <div className="appContent">
          <Router history={browserHistory}>
            {ConfigRoutes.routes.map(route => {
              return <Route key={uniqid} path={route.path} component={route.view} />
            })}
          </Router>
        </div>
        {this.backButton}
        {this.copyRight}
        {this.cookies}
      </div>
    )
  }
}

export default observer(Fintup)
