import { Component, React } from 'react'
import componentUtils from '../utils/componentUtils'
import constants from '../utils/constants'
import { FormattedMessage } from 'react-intl'
import transverser from '../utils/transverser'

class FintupComponent extends Component {
  constructor(props) {
    super(props)
    this.setStatePromise = componentUtils.setStatePromise.bind(this)
  }

  _getState(key, defaultValue) {
    return transverser.get(key, defaultValue)(this.state) || defaultValue
  }

  commonHandleChange(data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    let firstDotPosition = fieldName.indexOf('.')
    if (firstDotPosition === -1) {
      return this.setStatePromise({ [fieldName]: fieldValue })
    } else {
      let baseStateObjectName = fieldName.substring(0, firstDotPosition)
      let finalObjectName = fieldName.substring(firstDotPosition + 1)
      let baseObject = this.state[baseStateObjectName]
      transverser.set(finalObjectName, fieldValue)(baseObject)
      return this.setStatePromise({ [baseStateObjectName]: baseObject })
    }
  }

  commonCheckboxChange(data) {
    let fieldName = data.name
    let fieldValue = data.value
    let firstDotPosition = fieldName.indexOf('.')
    if (firstDotPosition === -1) {
      return this.setStatePromise({ [fieldName]: fieldValue })
    } else {
      let baseStateObjectName = fieldName.substring(0, firstDotPosition)
      let finalObjectName = fieldName.substring(firstDotPosition + 1)
      let baseObject = this.state[baseStateObjectName]
      transverser.set(finalObjectName, fieldValue)(baseObject)
      return this.setStatePromise({ [baseStateObjectName]: baseObject })
    }
  }

  commonTextareaChange(name, data) {
    //console.log("data: ", name)
    //console.log("data inner ", data.target.innerHTML)
    this.setStatePromise({
      [name]: data.target ? data.target.innerHTML : data
    });
  }

  handleServiceError(errorResponse) {
    let alertData = {
      alertText: errorResponse.message || <FormattedMessage id="general.error.unknown" />,
      alertType: constants.alertType.ERROR
    }
    this.setStatePromise({ alert: alertData })
    console.error(errorResponse)
    alert(alertData.alertText)
  }
}
export default FintupComponent
