import { extendObservable } from 'mobx'
import ProductMobx from './ProductsMobx'
import navUtils from './navUtils'

class UserMobx {
  constructor () {
    extendObservable(this, {
      user: {},
      backUrl: null
    })
  }

  resetBackUrl () {
    this.backUrl = null
  }

  setBackUrl (url) {
    this.backUrl = url
  }

  goToBackUrl (defaultUrl) {
    let targetUrl
    if (this.backUrl !== null) {
      targetUrl = this.backUrl
      this.resetBackUrl()
    } else {
      targetUrl = defaultUrl || '/'
    }
    return navUtils.goToInternal(targetUrl)
  }

  get getUser () {
    return this.user
  }

  setUser (user) {
    this.user = {...user}
    sessionStorage.setItem('fintupBackofficeUser', JSON.stringify(this.user))
    ProductMobx.resetProducts()
  }

  restoreUser () {
    let data = sessionStorage.getItem('fintupBackofficeUser')
    if (data && data !== 'null') {
      let dataObj = JSON.parse(data)
      this.user = dataObj
    }
  }
}
var UserController = new UserMobx()
export default UserController
