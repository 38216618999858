import React from 'react'
import './OperationData.css'
import FintupComponent from '../FintupComponent'
import { FormattedMessage } from 'react-intl'
import FintupButton from '../common/forms/FintupButton'
import EditLabel from '../common/forms/EditLabel'
import RSVP from 'rsvp'
import classNames from 'classnames'

/*
  Props:
    textKey: Texto
    value: Valor a mostrar
    formaterValue: Funcion para formatear el valor
    actionText: Texto boton de accion
    action: Funcion al pulsar el boton de accion
    editable: Indica que el dato se puede editar
    editAction: Indica la funcion a la que se llamara cuando se hace el cambio
    showEmpty: Pinta el componente aunque no tenga valor
*/
class OperationData extends FintupComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  // En caso de que no existe ni value ni values el componente no se renderiza
  get renderComponent() {
    return this.props.value || (this.props.values && this.props.values.lenght > 0) || this.props.showEmpty
  }

  editAction(value, callbackData) {
    return this.props.editAction
      ? this.props.editAction(value)
      : RSVP.resolve()
  }

  get renderValue() {
    if (this.props.editable) {
      return (
        <EditLabel editAction={this.editAction.bind(this)} text={this.value} />
      )
    }
    else if (this.props.html) {
      return <div dangerouslySetInnerHTML={{ __html: this.value }}></div>
    }
    else {
      return this.value
    }
  }

  get value() {
    if (this.props.value) {
      let propValue = this.props.value
      if (this.props.formaterValue) {
        propValue = this.props.formaterValue(propValue)
      }
      return propValue
    }

    if (this.props.values) {
      return this.props.values.map(item => (
        <p key={`${item.value}`}>
          {item.text}
        </p>
      ))
    }

    return undefined
  }

  get renderActionButton() {
    if (this.props.actionText && this.props.action) {
      return <FintupButton className="margin-left" textKey={this.props.actionText} action={this.props.action} />
    } else {
      return ''
    }
  }

  render() {
    let renderClasses = classNames('valueContainer', this.props.className)
    if (this.renderComponent) {
      return (
        <div className="row operationData">
          <div className="col-xs-4">
            <div className="labelContainer">
              <FormattedMessage id={this.props.textKey} />
            </div>
          </div>
          <div className="col-xs-8">
            <div className={renderClasses}>
              {this.renderValue}
              {this.renderActionButton}
            </div>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }
}

export default OperationData
