import React, { Component, createRef } from 'react'
import transverser from '../../../utils/transverser'
import utils from '../../../utils/utils'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import DataTransfer from 'fbjs/lib/DataTransfer'
import classNames from 'classnames'
import './InputTextArea.scss'
import FintupIcon from '../FintupIcon'
import _ from 'lodash'

/**
 * addonControl: Para mostrar los iconos de aceptar cancelar dentro del input
 * append Text para añadir al input como append text
 * disabled: Deshabilita el componente
 * innerInput: Indica que es un input de tipo inner, tiene dentro del input el nombre del campo como en la pagina de mi perfil
 * innerLabelWidth: Indica el ancho del label cuando es un input de tipo inner se pone el ancho en pixeles sin el px
 * labelSize: Se utiliza cuando es horizontal para definir el espacio del label
 * leftAction: Accion que se lanza al hacer click sobre el addonIzquierdo en caso de existir
 * maxLength: Longitud maxima del input
 * pasteTransform: Funcion que al pegar transforma el dato pegado
 * readOnly: Pone el input como readOnly
 * rightAddonAction: Accion que se lanza sobre el addon derecho en cas de existir
 * value: Valor que se le pasa al input
*/
class InputTextArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      props: props,
      value: transverser.get('value', '')(this.props),
    }

  }

  componentDidMount() {
    if (this.textAreaRef) {
      this.multilineTextarea.style.height = 'auto';
    }
  }

  handlePaste(ev) {
    let data = new DataTransfer(ev.clipboardData)
    let text = data.getText()
    if (this.props.pasteTransform) {
      ev.preventDefault()
      text = this.props.pasteTransform(text)
      let newEvent = {
        target: {
          value: text,
          name: this.props.name
        }
      }
      this.handleChange(newEvent)
    }
  }

  handleChange(ev) {
    this.multilineTextarea.style.height = "auto";
    this.multilineTextarea.style.height = this.multilineTextarea.scrollHeight + "px";

    let fieldValue = ev.target.value

    if (this.props.type === 'number' && this.props.maxLength) {
      let valueString = fieldValue ? `${fieldValue}` : '0'
      if (valueString.length > parseInt(this.props.maxLength, 10)) {
        return
      }
    }

    this.setState({ value: fieldValue })
    if (this.props.callBackOnChange) {
      let data = {
        ev: ev,
        name: this.props.name,
        value: fieldValue
      }
      this.props.callBackOnChange(data)
    }
  }

  get labelElementClasses() {
    return classNames(
      this.props.horizontal ? `col-sm-${this.props.labelSize} col-form-label ` : '',
      this.props.htmlLabel ? 'htmlLabel' : '',
      this.props.showDivider ? 'dividerLabel' : '',
      this.props.rightLabel ? 'rightLabel' : '',
      'newStyleGrid'
    )
  }

  get inputSizeClasses() {
    let labelSize = this.props.horizontal ? parseInt(this.props.labelSize, 10) : 0
    let inputSize = this.props.inputSize || 12 - labelSize
    return classNames(
      `col-sm-${inputSize}`,
      'newStyleGrid',
      { 'paddingRight15': this.props.showDivider }
    )
  }

  get inputClasses() {
    return classNames('form-control', this.props.inputClassName, { 'fLeft': this.props.append })
  }

  get containerClasses() {
    return classNames(
      'inputTextArea',
      this.props.className,
      { 'innerInput': this.props.innerInput }
    )
  }

  get legend() {
    let legendClassContainer = classNames('full-width', this.props.leftLegend ? '' : 'text-right')
    let legend = this.props.legendTextKey ? (
      <div className={legendClassContainer}>
        <small className="form-text text-muted inputLegendLink" role="link" tabIndex="0" onClick={this.props.legendAction} onKeyPress={utils.noop}><FormattedMessage id={this.props.legendTextKey} /></small>
      </div>
    ) : ''
    return legend
  }

  get inputStyles() {
    let styles = {}
    if (this.props.innerLabelWidth) {
      styles = _.merge(styles, { paddingLeft: `${this.props.innerLabelWidth + 5}px` })
    }
    return styles
  }

  get labelStyles() {
    let styles = {}
    if (this.props.innerLabelWidth) {
      styles = _.merge(styles, { width: `${this.props.innerLabelWidth}px` })
    }
    return styles
  }

  inputElement() {
    let placeHolder = this.props.placeholder ? this.props.intl.formatMessage({ id: this.props.placeholder }) : ''
    let inputType = this.props.type || 'text'
    let inputElement = (
      <div className={this.inputContainerClasses}>
        {this.addonContent}

        <textarea
          ref={ref => this.multilineTextarea = ref}
          style={this.inputStyles}
          disabled={this.props.disabled}
          type={inputType}
          /*onKeyDown={this.props.onkeydown}*/
          maxLength={this.props.maxLength}
          readOnly={this.props.readOnly}
          className={this.inputClasses}
          id={this.props.name}
          name={this.props.name}
          placeholder={placeHolder}
          value={this.props.value}
          onPaste={this.handlePaste.bind(this)}
          onChange={this.handleChange.bind(this)}
        />
        {this.appendContent}
      </div>
    )
    return inputElement
  }

  get appendContent() {
    return this.props.append
      ? (
        <span className="appendText">{this.props.append}</span>
      )
      : ''
  }

  requiredElement() {
    let requiredElement = this.props.required ? <span className="required-label">*</span> : ''
    return requiredElement
  }

  renderInnerInput() {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.labelElement}
          {this.inputElement()}
        </div>
      </div>
    )
  }

  horizontarRender() {
    return (
      <div className="form-group row">
        {this.labelElement}
        <div className={this.inputSizeClasses}>
          {this.inputElement()}
        </div>
      </div>
    )
  }

  horizontarRightRender() {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.inputElement()}
        </div>
        {this.labelElement}
      </div>
    )
  }

  verticalRender() {
    return (
      <div className="form-group">
        {this.labelElement}
        {this.requiredElement()}
        {this.inputElement()}
        {this.legend}
      </div>
    )
  }

  get labelElement() {
    let labelText = this.props.label
      ? this.props.htmlLabel
        ? <FormattedHTMLMessage id={this.props.label} />
        : <FormattedMessage id={this.props.label} />
      : ''
    let labelElement = this.props.label ? <label style={this.labelStyles} className={this.labelElementClasses} htmlFor={this.props.name}>{labelText}</label> : ''
    return labelElement
  }

  get renderTemplate() {
    let horizontarlInput = this.props.horizontal
    return this.props.innerInput
      ? this.renderInnerInput()
      : horizontarlInput
        ? this.props.rightLabel
          ? this.horizontarRightRender()
          : this.horizontarRender()
        : this.verticalRender()
  }

  render() {
    return (
      <div className={this.containerClasses}>
        {this.renderTemplate}
      </div>
    )
  }
}

export default injectIntl(InputTextArea)
