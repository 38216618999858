import React, { Component } from 'react'
import classNames from 'classnames'
import constants from '../../utils/constants'
import './Alert.css'

class Alert extends Component {
  constructor (props) {
    super(props)
    this.state = {props: props}
  }

  render () {
    let showAlertClass = this.props.show ? '' : 'hideAlert'
    let alertTypeClass = (() => {
      switch (this.props.type) {
        case constants.alertType.ERROR:
          return 'alert-danger'
        case constants.alertType.SUCCESS:
          return 'alert-success'
        default:
          return 'alert-danger'
      }
    })()
    let alertClasses = classNames('alert', alertTypeClass, showAlertClass)
    return (
      <div data-componen="alert" className={alertClasses} role="alert">
        {this.props.text}
      </div>
    )
  }
}

export default Alert
