import { browserHistory } from 'react-router'
import SessionController from './SessionMobx'
import RSVP from 'rsvp'
import urls from '../utils/urls'
import utils from '../utils/utils'

const $ = window.$

function goToInternal (url) {
  $('.navbar-toggle[aria-expanded="true"]').click()
  utils.scrollToTop()
  browserHistory.push(url)
  return RSVP.resolve()
}

function goToInitUrl () {
  let token = SessionController.token
  let logoUrl = token && token !== '' ? urls.internal.initUrl : urls.internal.login
  goToInternal(logoUrl)
}

function goToExternal (url, promiseResult) {
  window.location.href = url
  return promiseResult ? RSVP.resolve() : RSVP.reject()
}

function closeMenu () {
  $('.navbar-toggle[aria-expanded="true"]').click()
}

function logoutRedirect (emulatedParam) {
  let finalUrl = urls.internal.login
  if (emulatedParam) {
    finalUrl = urls.internal.helpdesk
  }
  goToInternal(finalUrl)
}

function openWindow (url) {
  window.open(url, '_blank')
}

function openPrivateWindow (url, token) {
  if (url.indexOf('?') >= 0) {
    url = `${url}&token=${token}`
  } else {
    url = `${url}?token=${token}`
  }
  openWindow(url)
}

let navUtils = {
  goToInternal: goToInternal,
  closeMenu: closeMenu,
  goToInitUrl: goToInitUrl,
  goToExternal: goToExternal,
  logoutRedirect: logoutRedirect,
  openWindow: openWindow,
  openPrivateWindow: openPrivateWindow
}

export default navUtils
