let config = {
  hotConfigUrl: '/json/externalConfig_backoffice.json',
  needLogin: true,
  apiBaseUrl: 'https://sapi.fintup.com',
  cdnBaseUrl: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com',
  cdnEnviroment: 'pro',
  mobileMinWidth: 767,
  captchaKey: '6LdrykIUAAAAAC3tAQ43Z72kQfTyj5pXv-bmxuWJ',
  mapKey: 'AIzaSyA6ejegk0qAT-eIF0-CGmzeiHSiO4ous9o',
  captchaTimeout: 5000,
  captchaEnable: true,
  suitabilityTestKey: 'f89g0eajlrvihln7qlntfqs6k7',
  showMap: true,
  googleAnalytics: 'UA-115030746-1',
  googleAnalyticsEnabled: true,
  calendlyEnable: true,
  calendlyUrl: 'https://calendly.com/fintup',
  socialLogin: true,
  googleLoginApiKey: '186120502170-bviidljb6md38k3eo7rnig84ho0313be.apps.googleusercontent.com',
  urlFundKid: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/kids/[fundIsin].pdf',
  enableEditLabel: true,
  enableDocumentView: false,
  suitabilityValidateEmail: false,
  checkEmailInterval: 10000,
  showSimulatorSliders: false,
  signOperation: true
}

export default config
