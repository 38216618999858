import React from 'react'
import transverser from '../../../utils/transverser'
import classNames from 'classnames'
import FintupComponent from '../../FintupComponent'
import FontAwesome from 'react-fontawesome'
import InputTextLabel from './InputTextLabel'
import uniqid from 'uniqid'
import './EditLabel.css'
import config from '../../../config/config'
import RSVP from 'rsvp'

class EditLabel extends FintupComponent {
  /*
    Config:
      config.captchaEnable: Habilita y deshabilita el funcionamiento del componente
    Props:
      edit: Indica si esta en estado lectura o estado edicion
      text: Texto mostrado
      placeholder: Placeholder que se mostrara en el input en la edicion
      editAction: Funcion que realizará el cambio de alias
      callbackData: Datos de callback devueltos a la funcion de editar para obtener refencias
   */
  constructor (props) {
    super(props)
    let text = transverser.get('text', '')(this.props)
    this.state = {
      props: props,
      text: text,
      editText: text,
      edit: this.props.edit
    }
  }

  componentWillReceiveProps (newProps) {
    let newText = transverser.get('text', '')(newProps)
    if (newText !== this.state.text) {
      this.setStatePromise({text: newText})
    }
  }

  get containerClasses () {
    return classNames('editLabel', this.props.className)
  }

  changeMode () {
    this.setStatePromise({ edit: true, editText: this.state.text })
  }

  cancelChange () {
    this.setStatePromise({ edit: false, editText: this.state.text })
  }

  successChange () {
    let successPromise = RSVP.resolve()

    if (this.props.editAction) {
      successPromise = this.props.editAction(this.state.editText, this.props.callbackData)
    }

    successPromise
      .then(() => {
        return this.setStatePromise({ edit: false, text: this.state.editText })
      })
      .catch(() => {
        return this.setStatePromise({ edit: false, editText: this.state.text })
      })
  }

  changeEditText (ev) {
    this.setStatePromise({ editText: ev.value })
  }

  onKeyPressActionInput (ev) {
    switch (ev.keyCode) {
      case 13:
        this.successChange()
        break
      case 27:
        this.cancelChange()
        break
      default:
    }
  }

  renderEdit () {
    return (
      <div className="flex-wrapper-start">
        <FontAwesome name="ban" className="changeAliasProductIconEdit noDetail flex-item-inherit" onClick={this.cancelChange.bind(this)} />
        <FontAwesome name="check" className="changeAliasProductIconEdit noDetail flex-item-inherit" onClick={this.successChange.bind(this)} />
        <InputTextLabel maxLength={this.props.maxLength} onkeydown={this.onKeyPressActionInput.bind(this)} className="flex-item" inputClassName="noDetail" placeholder={this.props.placeholder} name={uniqid()} value={this.state.editText} callBackOnChange={this.changeEditText.bind(this)} />
      </div>
    )
  }

  renderNoEdit () {
    return (
      <div className="flex-wrapper-start">
        <span className="flex-item">
          { config.enableEditLabel ? (
            <FontAwesome name="edit" className="changeAliasProductIcon noDetail" onClick={this.changeMode.bind(this)} />
          ) : (
            ''
          )}
          {this.state.text}
        </span>
      </div>
    )
  }

  get renderTemplate () {
    let edit = this.state.edit
    return edit
      ? this.renderEdit()
      : this.renderNoEdit()
  }

  render () {
    return (
      <div className={this.containerClasses}>
        {this.renderTemplate}
      </div>
    )
  }
}

export default EditLabel
