import _ from 'lodash'
import globalConfig from './config.common'
import SessionController from '../core/SessionMobx'

let mergeConfig = {}
let location = window.location.href

console.log(`Cargando la configuracion para la url -> ${location}`)
if (location.startsWith('http://localhost')) {
  console.log('Cargando la configuracion local')
  let enviromentConfig = require('./config.local')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('prebackoffice.fintup.com/') !== -1 || location.indexOf('d2azorllb6oxny.cloudfront.net') !== -1) {
  console.log('cargando la configuracion pre')
  let enviromentConfig = require('./config.pre')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('front-test.fintup.com') !== -1) {
  console.log('cargando la configuracion test')
  let enviromentConfig = require('./config.test')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('backoffice.clienteswebapp.com') !== -1) {
  console.log('cargando la configuracion test')
  let enviromentConfig = require('./config.clienteswebapp')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
}

let config = _.merge(globalConfig, mergeConfig)
function getBooleanConfig(name) {
  console.log("CONFIG: ", config)
  let value = config[name]
  if (value) {
    return JSON.parse(value)
  } else {
    return false
  }
}

function getFlowMeterConfig(flowMeterName) {
  let userDni = SessionController.user.dni
  let flowMeterData = config[flowMeterName] || ''
  if (flowMeterData === '*') return true
  let aFlowMeterData = flowMeterData.split(',').map(a => _.trim(a))
  return _.includes(aFlowMeterData, userDni)
}

export { getBooleanConfig, getFlowMeterConfig }

export default config
