import React from 'react'
import Spinner from 'react-spinkit'
import FintupComponent from '../FintupComponent'
import classNames from 'classnames'
import './LoadingApp.css'

class LoadingApp extends FintupComponent {
  get componentClasses () {
    return classNames('loadingApp', this.props.className)
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <Spinner name="ball-scale-multiple" fadeIn="none" />
      </div>
    )
  }
}

export default LoadingApp
