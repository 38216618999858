import React, { Component } from 'react'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import './SelectLabel.css'
import transverser from '../../../utils/transverser'
import classNames from 'classnames'
import _ from 'lodash'
/*
  Props:
    disabled: Deshabilita el elemento
*/
class SelectLabel extends Component {
  constructor (props) {
    super(props)

    let selectedLabel = ''
    let selectedValue = transverser.get('selectedValue', '')(this.props)
    if (selectedValue !== '' && this.props.options) {
      selectedLabel = _.find(this.translateOptions, function (option) {
        return option.value === selectedValue
      })
    }
    selectedLabel = selectedLabel || ''

    this.state = {
      props: props,
      value: transverser.get('selectedValue', '')(this.props),
      label: selectedLabel,
      searchable: _.isUndefined(this.props.searchable) ? true : this.props.searchable
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.selectedValue !== nextProps.selectedValue) {
      this.setState({ value: nextProps.selectedValue })
    }
  }

  handleChange (data) {
    if (data) {
      let fieldValue = data.value
      let labelValue = data.label
      this.setState({ value: fieldValue, label: labelValue })
      if (this.props.callBackOnChange) {
        let data = {
          ev: {
            target: {
              name: this.props.name,
              value: fieldValue
            }
          },
          name: this.props.name,
          value: this.state.value,
          label: this.state.label
        }
        this.props.callBackOnChange(data)
      }
    }
  }

  get translateOptions () {
    return this.props.options.map(item => {
      return {
        label: item.labelText || this.props.intl.formatMessage({ id: item.label }),
        value: item.value
      }
    })
  }

  get inputSizeClasses () {
    let labelSize = this.props.horizontal ? parseInt(this.props.labelSize, 10) : 0
    let inputSize = 12 - labelSize
    return `col-sm-${inputSize}`
  }

  getLabelElement () {
    let labelText = this.props.label
      ? this.props.htmlLabel
        ? <FormattedHTMLMessage id={this.props.label} />
        : <FormattedMessage id={this.props.label} />
      : ''
    let labelElement = this.props.label ? <label className={this.labelElementClasses} htmlFor={this.props.name}>{labelText}</label> : ''
    return labelElement
  }

  getPlaceholder () {
    return this.props.placeholder ? this.props.intl.formatMessage({ id: this.props.placeholder }) : ''
  }

  getRequiredElement () {
    return this.props.required ? <span className="required-label">*</span> : ''
  }

  getInputElement () {
    return (
      <Select
        disabled={this.props.disabled}
        placeholder={this.getPlaceholder()}
        name={this.props.name}
        value={this.state.value}
        onChange={this.handleChange.bind(this)}
        options={this.translateOptions}
        clearable={false}
        searchable={this.state.searchable}
      />
    )
  }

  verticalRender () {
    return (
      <div className="form-group">
        {this.getLabelElement()}
        {this.getRequiredElement()}
        {this.getInputElement()}
      </div>
    )
  }

  horizontarRightRender () {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.getInputElement()}
        </div>
        {this.getLabelElement()}
      </div>
    )
  }

  horizontarRender () {
    return (
      <div className="form-group row">
        {this.getLabelElement()}
        <div className={this.inputSizeClasses}>
          {this.getInputElement()}
        </div>
      </div>
    )
  }

  get labelElementClasses () {
    return classNames(
      this.props.horizontal ? `col-sm-${this.props.labelSize} col-form-label` : '',
      this.props.htmlLabel ? 'htmlLabel' : '',
      this.props.showDivider ? 'dividerLabel' : '',
      this.props.rightLabel ? 'rightLabel' : ''
    )
  }

  get renderTemplate () {
    let horizontarlInput = this.props.horizontal
    return horizontarlInput
      ? this.props.rightLabel
        ? this.horizontarRightRender()
        : this.horizontarRender()
      : this.verticalRender()
  }

  render () {
    let componentClasses = classNames('selectLabel', this.props.className)
    return (
      <div className={componentClasses}>
        {this.renderTemplate}
      </div>
    )
  }
}

export default injectIntl(SelectLabel)
