import React from 'react'
import RDatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import FintupComponent from '../../FintupComponent'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import './DatePicker.css'

class DatePicker extends FintupComponent {
  /**
   * Props:
        formatDate: Formato en el que se eleva la fecha, a dia de hoy solo funciona DD/MM/YYYY revisar el evento onBlur
        horizontal: inidca una presentacion horizontal label - input del componente
        labelSize: indica el offset en grid de 12 que ocupa el label horizontarl
        name: Nombre del componente en que se mapeará al estado
        callBackOnChange: Funcion de cambio de valor
  */
  constructor(props) {
    super(props)
    let selectedDate = this.props.value
      ? this.props.formatDate
        ? moment(this.props.value, this.props.formatDate)
        : this.props.value
      : null

    console.log("value DATE: ", this.props.value)
    console.log("format DATE: ", this.props.formatDate)
    console.log("selected DATE: ", selectedDate)


    let minDate = this.props.minDate
      ? this.props.formatDate
        ? moment(this.props.minDate, this.props.formatDate)
        : this.props.minDate
      : null

    let maxDate = this.props.maxDate
      ? this.props.formatDate
        ? moment(this.props.maxDate, this.props.formatDate)
        : this.props.maxDate
      : null
    this.state = {
      selectedDate: selectedDate,
      maxDate: maxDate,
      minDate: minDate
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(date) {
    let fieldValue = this.props.formatDate && date ? date.format(this.props.formatDate) : date
    console.log(("handleChange date: ", date))
    this.setStatePromise({ selectedDate: date })
      .then(() => {
        if (this.props.callBackOnChange) {
          let data = {
            ev: {
              target: {
                name: this.props.name,
                value: fieldValue
              }
            },
            name: this.props.name,
            value: fieldValue
          }
          this.props.callBackOnChange(data)
        }
      })
  }

  handleOnBlur(ev) {
    let dateRawValue = ev.target.value
    let momentDate = moment(dateRawValue, this.props.formatDate)
    let minValid = true
    let maxValid = true
    if (momentDate.isValid()) {
      if (this.state.minDate) {
        minValid = momentDate.isSameOrAfter(this.state.minDate)
      }

      if (this.state.maxDate) {
        maxValid = momentDate.isSameOrBefore(this.state.maxDate)
      }

      if (minValid && maxValid) {
        this.handleChange(momentDate)
      } else {
        this.handleChange(this.state.selectedDate)
      }
    }
  }

  getInputElement() {
    let placeHolder = this.props.placeholder ? this.props.intl.formatMessage({ id: this.props.placeholder }) : ''
    return (
      <RDatePicker
        readOnly={this.props.readOnly}
        className="form-control"
        placeholderText={placeHolder}
        selected={this.state.selectedDate}
        onChange={this.handleChange}
        minDate={this.state.minDate}
        maxDate={this.state.maxDate}
        showYearDropdown
        /* onChangeRaw={(event) => this.handleChangeRaw(event)} */
        onBlur={this.handleOnBlur.bind(this)} />
    )
  }

  get labelElementClasses() {
    return classNames(
      this.props.horizontal ? `col-sm-${this.props.labelSize} col-form-label` : '',
      this.props.htmlLabel ? 'htmlLabel' : '',
      this.props.showDivider ? 'dividerLabel' : '',
      this.props.rightLabel ? 'rightLabel' : '',
      'datePickerLabel'
    )
  }

  getLabelElement() {
    let labelText = this.props.label
      ? this.props.htmlLabel
        ? <FormattedHTMLMessage id={this.props.label} />
        : <FormattedMessage id={this.props.label} />
      : ''
    let labelElement = this.props.label ? <label className={this.labelElementClasses} htmlFor={this.props.name}>{labelText}</label> : ''
    return labelElement
  }

  verticalRender() {
    return (
      <div className="form-group">
        {this.getLabelElement()}
        {this.getRequiredElement()}
        {this.getInputElement()}
      </div>
    )
  }

  getRequiredElement() {
    return this.props.required ? <span className="required-label">*</span> : ''
  }

  horizontarRightRender() {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.getInputElement()}
        </div>
        {this.getLabelElement()}
      </div>
    )
  }

  horizontarRender() {
    return (
      <div className="form-group row">
        {this.getLabelElement()}
        <div className={this.inputSizeClasses}>
          {this.getInputElement()}
        </div>
      </div>
    )
  }

  get renderTemplate() {
    let horizontarlInput = this.props.horizontal
    return horizontarlInput
      ? this.props.rightLabel
        ? this.horizontarRightRender()
        : this.horizontarRender()
      : this.verticalRender()
  }

  render() {
    return (
      <div className="datePicker">
        {this.renderTemplate}
      </div>
    )
  }
}

export default injectIntl(DatePicker)
