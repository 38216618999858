import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker from './registerServiceWorker'
import FintupInt from './components/FintupInt'
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-WZ8GW7D'
// }

// TagManager.initialize(tagManagerArgs)

ReactDOM.render(<FintupInt />, document.getElementById('root'))
// registerServiceWorker()
