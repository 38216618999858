import accounting from 'accounting'
import PasswordValidator from 'password-validator'
import EmailValidator from 'email-validator'
import cifjs from 'iscif'
import moment from 'moment'
import validator from 'validator'
import iban from 'iban'
import config from '../config/config'
import _ from 'lodash'
import RSVP from 'rsvp'
import constants from './constants'

const $ = window.$

let schema = new PasswordValidator()
schema
  .is().min(8)// Minimum length 8
  .is().max(25)// Maximum length 25
  .has().digits()// Must have digits
  .has().not().spaces()// Should not have spaces
  .has().letters()

function formatNumber(number) {
  let currencyTransform = {
    amount: number,
    currency: ''
  }
  return formatCurrency(currencyTransform)
}

function formatYear(number) {
  let opts = {
    precision: 0
  }
  let currencyTransform = {
    amount: number,
    currency: ''
  }
  return formatCurrency(currencyTransform, opts)
}

function formatCurrency(currency, opts = {}) {
  let _currency
  if (!_.isObjectLike(currency)) {
    _currency = {
      amount: currency,
      currency: '€'
    }
  } else {
    _currency = currency
  }

  let amount = _currency.ammount || _currency.amount
  let formatCurrency = ''
  if (currency) {
    let defaultOptions = {
      symbol: _currency.currency,
      precision: 2,
      thousand: '.',
      decimal: ',',
      format: {
        pos: '%v %s',
        neg: '- %v %s',
        zero: '%v %s'
      }
    }
    let options = _.merge(defaultOptions, opts)
    formatCurrency = accounting.formatMoney(amount, options)
  }
  return formatCurrency
}

function formatPorcentage100(porcentage) {
  return formatPorcentage(porcentage * 100, { precision: 0 })
}

function formatPorcentage(porcentage, opts) {
  let options = {
    symbol: '%',
    precision: 2,
    thousand: '.',
    decimal: ',',
    format: {
      pos: '%v %s',
      neg: '- %v %s',
      zero: '%v %s'
    }
  }
  let formatPorcentage = ''
  if (opts) {
    options = _.merge({}, options, opts)
  }
  if (porcentage) {
    formatPorcentage = accounting.formatMoney(porcentage, options)
  }
  return formatPorcentage
}

function validatePassword(password) {
  return schema.validate(password)
}

function validateEmail(email) {
  return EmailValidator.validate(email)
}

function parseDate(data) {
  if (_.isNull(data)) return null
  let date = data.date
  let format = data.format
  return date
    ? format
      ? moment(date, format)
      : moment(date)
    : date
}

function formatDate(millis) {
  return millis
    ? moment(millis).format('DD-MM-YYYY')
    : 'N/D'
}

function formatTimestamp(millis) {
  return millis
    ? moment(millis).format('DD-MM-YYYY HH:mm:ss')
    : 'N/D'
}

function formatInteger(number) {
  return Math.trunc(number)
}

function formatDecimal(number, decimals) {
  return Number(number).toFixed(decimals)
}


/* data.date
data.format
data.maxDate
data.minDate
data.allowEmpty: por defecto false, en caso de true permite '', null y undefined */
function validateDate(data) {
  let { date, format, minDate, maxDate, allowEmpty } = data
  if (format) {
    date = parseDate({ date: date, format: format })
    minDate = parseDate({ date: minDate, format: format })
    maxDate = parseDate({ date: maxDate, format: format })
  }

  if (allowEmpty && (date === '' || date === null || _.isUndefined(date))) {
    return true
  }

  if (date === null || _.isUndefined(date)) {
    return false
  }

  if (!date.isValid()) {
    return false
  }

  if (minDate && date.isBefore(minDate)) {
    return false
  }

  if (maxDate && date.isAfter(maxDate)) {
    return false
  }

  return true
}

function noop() {
  return true
}

function randomNumber(toNumber) {
  return Math.floor(Math.random() * toNumber)
}

function validatePositiveAmount(value, options) {
  let _value = `${value}`
  let defaultOptions = {
    min: 1,
    max: 1000000000
  }
  return validator.isFloat(_value, _.merge(defaultOptions, options))
}

function validateAmount(value, options) {
  let result = validator.isFloat(value, options)
  return result
}

function isNif(dni) {
  var numero
  var letra
  var letras
  var expresionRegularDni = /^[XYZ]?\d{5,8}[A-Z]$/

  dni = dni.toUpperCase()

  if (expresionRegularDni.test(dni) === true) {
    numero = dni.substr(0, dni.length - 1)
    numero = numero.replace('X', 0)
    numero = numero.replace('Y', 1)
    numero = numero.replace('Z', 2)
    letra = dni.substr(dni.length - 1, 1)
    numero = numero % 23
    letras = 'TRWAGMYFPDXBNJZSQVHLCKET'
    letras = letras.substring(numero, numero + 1)
    if (letras !== letra) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

function isCif(cif) {
  return cifjs(cif)
}

function isPhone(phone) {
  return validator.isMobilePhone(phone, 'es-ES')
}

function isMobileDevice() {
  let windowWidth = window.outerWidth
  if (isInIframe()) {
    windowWidth = window.innerWidth
  }
  return windowWidth <= config.mobileMinWidth
}

function scrollToTop() {
  $('html, body').animate({ scrollTop: 0 })
}

function isNumber(number) {
  return !isNaN(number)
}

function isNie(nie) {
  var niePrefix = nie.charAt(0)

  switch (niePrefix.toUpperCase()) {
    case 'X': niePrefix = 0; break
    case 'Y': niePrefix = 1; break
    case 'Z': niePrefix = 2; break
    default: niePrefix = ''; break
  }

  return isNif(niePrefix + nie.substr(1))
}

function validateCP(value, options) {
  if (_.isNumber(value)) value = `${value}`
  return validator.isPostalCode(value, 'ES')
}

function isIban(value) {
  return iban.isValid(value)
}

function loadScript(scriptUrl, inBody) {
  return new RSVP.Promise(function (resolve, reject) {
    var aScript = document.createElement('script')
    aScript.type = 'text/javascript'
    aScript.src = scriptUrl
    if (inBody) {
      document.body.appendChild(aScript)
    } else {
      document.head.appendChild(aScript)
    }
    aScript.onload = function () {
      resolve()
    }
  })
}

function loadCss(cssUrl) {
  return new RSVP.Promise(function (resolve, reject) {
    var aScript = document.createElement('link')
    aScript.rel = 'stylesheet'
    aScript.href = cssUrl
    document.head.appendChild(aScript)
    aScript.onload = function () {
      resolve()
    }
  })
}

function removeArrayIndex(array, index) {
  return _.filter(array, function (item, indexItem) {
    return index !== indexItem
  })
}

function removeBlanks(value) {
  return replaceAll(value, constants.CHARS.BLANK, constants.CHARS.EMPTY)
}

function replaceAll(str, search, replacement) {
  return str.split(search).join(replacement)
}

function mapObject(object, mapperFunction) {
  let aObject = [].concat(object)
  let aMapObject = aObject.map(mapperFunction)
  return aMapObject[0]
}

function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

function isFlexi(product) {
  return product.productGroupType === 'CNP_FLEXI_PLUS' || product.productId === 20734
}
function isPensionPlan(product) {
  return product.productGroupType === 'CNP_PLANES' || product.productId === 20773
}
/**
 * Contract entity
 * @param {} product 
 */
function isUnitLink(product) {
  return product.productGroupType === 'CNP_INVERSION_LIBRE' || product.productGroupType === 'CNP_INVERSION_PLUS' ||
    product.productId === 20674 ||
    product.productId === 20677 ||
    product.productId === 20676
}
/**
 * Product entity
 * @param {*} product 
 */
function isProductUnitLink(product) {
  console.log("product isProductUnitLink", product)
  return product.processProductData === 'CNP_INVERSION_LIBRE' || product.processProductData === 'CNP_INVERSION_PLUS' ||
    product.id === 20674 ||
    product.id === 20677 ||
    product.id === 20676
}

function executeMobile(paramFunction) {
  if (isMobileDevice()) {
    return paramFunction()
  } else {
    return RSVP.resolve()
  }
}

function isEmpty(data) {
  return _.isUndefined(data) || data === null || _.trim(data) === ''
}

function hasBlanksSpaces(data) {
  return data.indexOf(' ') >= 0
}

function hasPoints(data) {
  return data.indexOf('.') >= 0
}

function noAnchor() {
  return undefined
}

function getUserFullName(user) {
  if (user) {
    let fullName = user.name
    if (user.surname && user.surname !== '') {
      fullName += ` ${user.surname}`
    }
    return fullName
  }
  return ''
}

let utils = {
  executeMobile: executeMobile,
  formatCurrency: formatCurrency,
  formatDate: formatDate,
  formatTimestamp: formatTimestamp,
  parseDate: parseDate,
  validateDate: validateDate,
  formatNumber: formatNumber,
  formatPorcentage: formatPorcentage,
  formatPorcentage100: formatPorcentage100,
  formatYear: formatYear,
  isCif: isCif,
  isIban: isIban,
  isMobileDevice: isMobileDevice,
  isNie: isNie,
  isNif: isNif,
  isNumber: isNumber,
  isPhone: isPhone,
  isUnitLink: isUnitLink,
  isProductUnitLink: isProductUnitLink,
  isFlexi: isFlexi,
  isPensionPlan: isPensionPlan,
  loadCss: loadCss,
  loadScript: loadScript,
  mapObject: mapObject,
  noop: noop,
  formatDecimal: formatDecimal,
  formatInteger: formatInteger,
  randomNumber: randomNumber,
  removeArrayIndex: removeArrayIndex,
  scrollToTop: scrollToTop,
  validateCP: validateCP,
  validateAmount: validateAmount,
  validateEmail: validateEmail,
  validatePassword: validatePassword,
  validatePositiveAmount: validatePositiveAmount,
  removeBlanks: removeBlanks,
  replaceAll: replaceAll,
  isEmpty: isEmpty,
  hasBlanksSpaces: hasBlanksSpaces,
  hasPoints: hasPoints,
  fixLinter: {
    noAnchor: noAnchor
  },
  getUserFullName
}

export default utils
